/* -------------------------------------------------------- */
// UPDATE UIKIT
/* -------------------------------------------------------- */

:root {
  --color-font: #494e5a;
  --color-orange-500: #fb5607;
  --color-red-50: #fdeced;
  --safe-area-inset-bottom-px: calc(34 * 1px);
}

@supports (env(safe-area-inset-bottom)) {
  :root {
    --safe-area-inset-bottom-px: calc(34 * 1px);
    --safe-area-inset-top-px: calc(var(--safe-area-inset-top) * 1px);
  }
}

@supports (constant(safe-area-inset-bottom)) {
  :root {
    --safe-area-inset-bottom-px: calc(34 * 1px);
    --safe-area-inset-top-px: calc(var(--safe-area-inset-top) * 1px);
  }
}

/* -------------------------------------------------------- */
// safari debug test
/* -------------------------------------------------------- */

.ion-page {
  display: flex !important;
  width: 100% !important;
  height: 100% !important;
}

/* -------------------------------------------------------- */
// google map
/* -------------------------------------------------------- */

.gm-style-iw-chr {
  .gm-style-iw-ch {
    padding-top: 13px !important;
  }
  button {
    display: none !important;
  }
}
.gm-style {
  text-align: center !important;
}

// custom google map results display
// can't do it specific for a page
// this css impact all search google map inputs results
.pac-container {
  box-shadow:
    0 27px 27px rgba(0, 0, 0, 0.09),
    0 7px 15px rgba(0, 0, 0, 0.15) !important;
  border: 1px solid var(--color-neutral-300) !important;
  border-radius: 16px !important;
  margin-top: 8px !important;
  transform: scaleX(1.02) !important;
  @media screen and (max-width: 1023px) {
    box-shadow: none !important;
    border: none !important;
    border-radius: 0 !important;
    margin-top: 16px !important;
  }
  .pac-item {
    border: none !important;
    padding: 8px 16px !important;
    background-color: var(--color-neutral-50) !important;
    &:hover {
      background-color: var(--color-neutral-200) !important;
    }
    .pac-icon {
      background-image: url('assets/svg/pin-results-v2.svg');
      background-size: 16px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.gmap {
  map,
  area {
    display: block;
    height: 100%;
  }
}

// hide controls on mobile
.gmap-new {
  .gmnoprint,
  .gm-bundled-control {
    @media screen and (max-width: 1023px) {
      display: none !important;
    }
  }
}

// hide close button on infoWindows browser only
.gmap.browser {
  .gm-style .gm-style-iw {
    text-align: center !important;
    button {
      display: none !important;
    }
  }
}
// Display call-center buttons when kameleoon add 'call-center' class to body
.call-center {
  .call-center-container {
    display: block !important;
  }
}

// Typeform inscription
#typeform-inscription {
  max-height: calc(100% - 60px) !important;
  height: 100% !important;
  width: 100%;
  padding: 0;
  div {
    &:first-child {
      height: 100%;
      width: 100%;
    }
  }
}
#typeform-inscription-page-loader {
  .page-loader {
    height: calc(100% - 60px);
    min-height: unset;
  }
}

// DEBUG
.axeptio_mount {
  display: none !important;
}

// MAP TEACHER V3
.slider-map-teacher {
  .dirslider_btLeft {
    margin-left: -10px !important;
  }
  .dirslider_btRight {
    margin-right: -10px !important;
  }
  .dirslider_btLeft,
  .dirslider_btRight {
    // display: none!important;
    // opacity: 1!important;
    background-size: 30px !important;
    filter: brightness(2) drop-shadow(0 0 1px rgba(0, 0, 0, 0.8)) !important;
    &:hover {
      // display: none!important;
      // opacity: 0!important;
    }
  }
}

// ELIGIBILITE CPF FORMS
#typeform-eligibilite-cpf,
#yousign-eligibilite-cpf {
  flex: 1 1 0;
  padding: 0;
  div {
    &:first-child {
      height: 100%;
      width: 100%;
    }
  }
  #yousign-iframe {
    height: 100%;
    width: 100%;
  }
}

#customModalVideo {
  .evs_modal_container.active {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .evs_modal_content {
    overflow: hidden !important;
    padding-right: 0 !important;
  }
  video-comp {
    display: block;
    max-height: 1080px;
    height: auto;
    background-color: rgba(0, 0, 0, 0.8);
    video {
      max-height: 1080px;
      height: auto;
      border-radius: 0;
    }
  }
  @media screen and (max-height: 1080px) {
    video-comp {
      max-height: 720px;
      video {
        max-height: 720px;
      }
    }
  }
  @media screen and (max-height: 720px) {
    video-comp {
      max-height: 480px;
      video {
        max-height: 480px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .evs_modal_bg {
      background-color: rgba(0, 0, 0, 90%) !important;
    }
    video-comp {
      height: 100%;
      background-color: var(--color-black);
    }
    video {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); // x - y
      max-height: 100% !important;
      height: 100% !important;
      width: 100% !important;
      z-index: 9000;
    }
  }
  .cta-post-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
  }
  .cta-post-video.active {
    opacity: 1;
    pointer-events: initial;
  }
}

.reset-stats {
  padding: 16px 0;
  text-align: center;
}

.evs_toaster {
  span {
    display: flex;
    align-items: center;
    img {
      margin-right: 8px;
    }
  }
}

#customModalYousign {
  .evs_modal_container {
    @media screen and (max-width: 767px) {
      width: calc(100% - 16px) !important;
      height: calc(100% - 16px) !important;
      max-width: calc(100% - 16px) !important;
      max-height: calc(100% - 16px) !important;
      border-radius: 24px !important;
    }
    .evs_modal_content {
      max-height: 100% !important;
      max-width: 100% !important;
      padding: 32px !important;
      margin: 0 !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media screen and (max-width: 767px) {
        width: 100% !important;
        height: 100% !important;
        padding: 8px !important;
      }
      .close-modal-button {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: flex-end;
        padding: 2px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: var(--color-neutral-100);
        margin: 0 0 8px 0;
      }
      .success-header-wrapper {
        text-align: center;
        h5 {
          font-size: 18px;
          font-weight: 700;
          color: var(--color-font);
          margin: 0 0 8px 0;
        }
        p {
          font-size: 18px;
          font-weight: 400;
          color: var(--color-font);
          margin: 0 0 16px 0;
        }
        .btn {
          padding: 8px;
          border-radius: 12px;
          margin: 0 0 16px 0;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
      }
      #yousign-iframe {
        height: 100%;
        width: 100%;
      }
    }
  }
}

/* -------------------------------------------------------- */
// lottie
/* -------------------------------------------------------- */

.fx-lottie {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: auto;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  lottie-player {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    margin: auto;
    height: auto;
  }
}
.fx-lottie.default-lottie,
.fx-lottie.high-five,
.fx-lottie.thumbs-up,
.fx-lottie.signe-jul {
  height: calc(100% - 150px);
  @media screen and (max-width: 767px) {
    height: calc(100% - 80px);
  }
  fireworks-comp {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0.8;
  }
  lottie-player {
    margin-top: -100px;
    max-width: 450px;
    @media screen and (max-width: 767px) {
      margin-top: -80px;
      max-width: 60%;
    }
    @media screen and (max-width: 545px) {
      margin-top: -80px;
      max-width: 90%;
    }
  }
  .congrats-text {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 32px;
    margin: auto;
    text-align: center;
    padding: 0 16px;
    zoom: 1.2;
    @media screen and (max-width: 767px) {
      zoom: 1.1;
    }
    h3 {
      color: var(--color-orange-500);
      margin-bottom: 0;
    }
    p {
      font-weight: bold;
    }
  }
}
.fx-lottie.thumbs-up {
  lottie-player {
    max-width: 350px;
    @media screen and (max-width: 767px) {
      max-width: 50%;
    }
    @media screen and (max-width: 545px) {
      max-width: 65%;
    }
  }
}
.fx-lottie.signe-jul {
  lottie-player {
    max-width: 450px;
    @media screen and (max-width: 767px) {
      max-width: 50%;
    }
    @media screen and (max-width: 545px) {
      max-width: 65%;
    }
  }
}
.fx-lottie.tracking,
.fx-lottie.heureux-kass {
  height: calc(100% - 150px);
}

.fx-lottie.heureux-kass {
  .motion-text {
    lottie-player {
      position: initial;
      @media screen and (max-width: 767px) {
        max-width: 450px;
      }
      @media screen and (max-width: 540px) {
        margin-top: -30px;
        max-width: 350px;
      }
    }
    div {
      max-width: 450px;
      margin: auto;
      margin-top: -50px;
    }
  }
}
.lottie-container-inscription-examen {
  canvas {
    top: 0;
    left: 0;
  }
}

/* -------------------------------------------------------- */
// ion-header/footer nav rules mobile/desktop
/* -------------------------------------------------------- */

ion-footer {
  display: none;
}
.device {
  top-nav-component {
    display: none;
  }
  ion-footer {
    display: initial;
  }
}
.device.hideFooterNav {
  ion-footer {
    display: none;
  }
}
.ion-content-container {
  padding: 16px;
}

/* -------------------------------------------------------- */

// HIDE LASTPASS ICON
*[data-lastpass-icon-root] {
  display: none !important;
}

.tunnel-paiement-container * {
  user-select: none;
}
.tunnel-paiement-container {
  input {
    -webkit-user-select: text !important;
  }
  input[type='email'] {
    background-image: url(assets/svg/tunnel-mail-ico.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 16px) center;
    background-size: 16px 14px;
    padding-right: 40px !important;
  }
  input:not([type='color']).ng-invalid.ng-touched,
  textarea.ng-invalid.ng-touched,
  select.ng-invalid.ng-touched {
    background-color: var(--color-red-50) !important;
  }
  label {
    color: var(--color-neutral-500) !important;
  }
}

.tunnel-register-container {
  #tunnelFormPostalCode {
    position: relative;
    z-index: 1000;
    display: block;
  }
  .evs-form div,
  .evs-dark-form div {
    margin-bottom: 8px !important;
    .evs_options {
      position: absolute !important;
      background-color: var(--color-white) !important;
      max-height: 234px !important;
      .evs_option {
        margin-bottom: 0 !important;
        height: 51px !important;
        display: flex !important;
        align-items: center !important;
        padding: 0 16px !important;
      }
    }
    .evs_delete {
      bottom: 8px !important;
      background-image: url('assets/svg/tunnel-delete-ico.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16px 16px;
      width: 16px;
      height: 16px;
      filter: grayscale(100%) brightness(1.5);
      right: 14px !important;
      i {
        display: none !important;
      }
    }
  }
  #tunnelFormPostalCode {
    .field-error {
      margin: 0 0 8px 16px !important;
    }
  }
}

.tunnel-select-hour-container {
  #tunnelFormSelectHour {
    position: relative;
    z-index: 1000;
    display: block;

    .evs_inputSelect {
      background-color: var(--color-white) !important;
      border-color: var(--color-neutral-400) !important;
      filter: none !important;
    }
    .label-select-value {
      margin: 8px 0 0 0 !important;
    }
    .field-error {
      display: none !important;
    }
  }
  .evs-form div,
  .evs-dark-form div {
    .evs_options {
      position: absolute !important;
      background-color: var(--color-white) !important;
      max-height: 234px !important;
      .evs_option {
        margin-bottom: 0 !important;
        height: 51px !important;
        display: flex !important;
        align-items: center !important;
        padding: 0 16px !important;
      }
    }
  }
}

.evs_autocomplete {
  .evs_delete {
    bottom: 0 !important;
  }
}

// .evs-form div input, .evs-dark-form div input {
//   border-radius: 0!important;
//   padding-right: 35px!important;
// }
// .evs-form div input.double-icon, .evs-dark-form div input.double-icon {
//   border-radius: 0!important;
//   padding-right: 55px!important;
// }

// .register-form .evs-form span.field-error, .register-form .evs-dark-form span.field-error {
//   position: absolute!important;
//   right: 0!important;
//   bottom: -15px!important;
// }

// .register-form .evs-form .evs_autocomplete span.field-error, .register-form .evs-dark-form .evs_autocomplete span.field-error {
//   bottom: -20px!important;
// }
// .register-form .evs-form .evs_autocomplete .evs_options, .register-form .evs-dark-form .evs_autocomplete .evs_options {
//   position: relative!important;
// }
// .register-form .evs-form .evs_autocomplete label, .register-form .evs-dark-form .evs_autocomplete label {
//   top: 16px!important;
// }

.above-modal {
  z-index: 4000;
}

// .form-icon-valid {
//   position: absolute;
//   right: 5px;
//   bottom: 16px;
//   width: 20px;
//   pointer-events: all;
// }
// .form-icon-invalid {
//   position: absolute;
//   right: 5px;
//   bottom: 15px;
//   pointer-events: all;
// }

// .double-form-icon {
//   right: 30px;
// }

// .fix-autocomplete {
//   bottom: 10px;
// }

.evs_inputSearch {
  background: none !important;
  background-image: none !important;
}
// .evs_inputSearch::placeholder {
//   color: white!important;
// }
// autocomplete-comp {
//   label {
//     margin-left: 0!important;
//   }
// }

// remove inner shadow on ios on inputs
textarea,
input[type] {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

input[type='date'],
input[type='date']::-webkit-date-and-time-value {
  text-align: left !important;
}

input[type='date']::-webkit-calendar-picker-indicator {
  right: 12px !important;
}

.btn-oui-non {
  padding: 14px 12px;
  border-radius: 12px;
  box-sizing: content-box;
}

.btn.light.white {
  background: var(--color-white);
}
@media (hover: hover) and (pointer: fine) {
  .btn.light.white:hover {
    background: var(--color-neutral-50) !important;
  }
}
.btn.light.white:active {
  background: var(--color-neutral-100) !important;
}

/* -------------------------------------------------------- */

.device,
serie-page {
  bloc-header-comp:not(.serie-failed) {
    .bloc-header {
      // @media screen and (max-width: 767px) {
      background-color: var(--color-white) !important;
      ion-icon {
        color: var(--color-gray-74) !important;
      }
      // }
    }
  }
}

/* -------------------------------------------------------- */

/*           ####  ionic modal custom size  ####            */

.box-shadow {
  box-shadow:
    0px 0px 3px rgba(0, 0, 0, 0.1),
    0px 3px 6px rgba(0, 0, 0, 0.1);
}

ion-modal,
modal-comp {
  user-select: none;
}

.evs_modal {
  .evs_modal_bg {
    user-select: initial !important;
  }
  // evs-5096
}
#customModalNorating {
  .evs_modal_content {
    margin: 24px 0 24px 24px !important;
    padding-right: 24px !important;
  }
}

.small-modal ion-modal::part(content) {
  width: 300px !important;
  height: 200px !important;
  border-radius: 8px !important;
  // @media screen and (max-height: 599px), screen and (max-width: 767px) {
  //   border-radius: 0 !important;
  //   width: var(--width) !important;
  //   max-width: var(--max-width) !important;
  //   height: var(--height) !important;
  // }
}
.medium-modal ion-modal::part(content) {
  width: 500px !important;
  max-width: 500px !important;
  max-height: 500px !important;
  @media screen and (max-width: 767px) {
    max-height: 400px !important;
  }
  border-radius: 8px !important;
  margin: 0 20px;

  ion-content,
  .overscroll,
  .ios,
  .hydrated,
  .scroll-y {
    --overflow: hidden !important;
    overflow: hidden !important;
    overflow-y: hidden !important;
  }
}

/* -------------------------------------------------------- */
/* TEMPORAIRE LE TEMPS QUE L'UIKIT SOIT MIS À JOUR*/
/* -------------------------------------------------------- */

.shimmer-arrow-right {
  float: right;
  width: 24px !important;
  margin-top: -20px !important;
  opacity: 0.7;
}

.shimmer {
  background-color: #e6e6e6 !important;
}
.dark-shimmer {
  background: #d7d7d7 !important;
}

.vertical-align-center {
  display: flex !important;
  align-items: center !important;
}

p {
  user-select: text !important;
}

.big-emojis {
  img {
    display: inline-block;
    height: 90px;
  }
}

.evs_tc_part_bl {
  @media screen and (max-width: 767px) {
    margin-bottom: 60px;
  }
}

.header-md:after,
.header-ios:after {
  background-image: none;
  bottom: 0;
  height: 1px;
  // box-shadow: 0px .5px 2px var(--color-gray-62);
  content: '';
}

.footer-md:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-image: none;
  background-repeat: repeat-x;
  background-position: left 0 top -2px;
  // box-shadow: 0px -.5px 3px var(--color-gray-62);
}

bloc-header-comp {
  display: block;
  position: relative;
  z-index: 100;
}

.border-left-highlight {
  background-color: var(--color-orange-500);
  position: absolute;
  left: 0;
  width: 5px;
  height: 52%;
  border-radius: 0 6px 6px 0;
}

.full-size,
.flip-box .object,
.flip-box .front,
.flip-box .back {
  width: 100%;
  height: 100%;
}

.slice,
.flip-box .front,
.flip-box .back,
.flip-box .flank {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
}

$fb-width: 100%;
$fb-height: 55px;

.flip-box {
  float: left;
  display: block;
  position: relative;
  z-index: 2;
  min-width: calc(300px - 2em);
  width: $fb-width;
  height: $fb-height;
  perspective: 400px;
}
.flip-box .object {
  position: absolute;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  transform-style: preserve-3d;
  transform: translateZ(-75px);
}
.flip-box .front {
  z-index: 1;
  height: 58px;
  background: var(--color-white);
  transform: rotateY(0deg) translateZ(25px);
}
.flip-box .back {
  z-index: 0;
  height: 58px;
  background: var(--color-white);
  transform: rotateY(180deg) translateZ(75px);
}
.flip-box .bottom,
.flip-box .top {
  width: 150px;
  height: 55px;
  left: 75px;
  background: var(--color-white);
  transform: rotateY(-90deg) translateZ(25px);
}
.flip-box.flip-up .object.rotate {
  transform: translateZ(-75px) rotateX(180deg);
}
.flip-box.flip-up .back {
  transform: rotateX(180deg) translateZ(25px);
}
.flip-box.flip-up .top,
.flip-box.flip-up .bottom {
  width: $fb-width;
  height: $fb-height;
  left: 0;
  top: 22px;
  transform: rotateX(-90deg) translateZ(-25px);
}

// fx types
// box
.flip-box.box-fx {
  .object {
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    .front,
    .back {
      // border-radius: 8px;
      border-radius: 6px;
      background: var(--color-white);
    }
    .top,
    .bottom {
      // border-radius: 8px;
      border-radius: 6px;
      background: var(--color-light);
    }
  }
}

/* Internet Explorer 10 only */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .object {
    position: relative;
    overflow: hidden;
    transform: none !important;
  }
  .object .back {
    top: 300px;
    z-index: 0;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
  }
  .object:hover .back {
    top: 0;
    z-index: 1;
    transform: rotateY(0deg) translateZ(0);
  }
}

// ****************************************
// * GLOBAL CSS FOR ALL PAGES APPLICATION *
// ****************************************

// temp - i put these css here but need to be moved into uikit

.margin-auto {
  margin: auto;
}

.failed-radial {
  min-width: 132px;
  min-height: 132px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px;
  border-radius: 50%;
  border: 12px solid var(--color-neutral-100);
  img {
    width: 50%;
    margin-top: -10%;
  }
  @media screen and (max-width: 425px) {
    min-width: 82px;
    min-height: 82px;
  }
}

.evs-radial-percent {
  .number {
    p {
      margin-top: 4px !important;
    }
  }
}

.evs_zoom_ico {
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.evs_zoom_ico.zoom_in {
  background-image: url('https://cdn3.iconfinder.com/data/icons/glypho-generic-icons/64/arrows-maximize-enlarge-64.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  -webkit-filter: invert(100%); /* Safari/Chrome */
  filter: invert(100%);
}
.evs_zoom_ico.zoom_out {
  background-image: url('http://cdn.onlinewebfonts.com/svg/img_469488.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 65%;
  -webkit-filter: invert(100%); /* Safari/Chrome */
  filter: invert(100%);
}

.evs_zoom_bg {
  position: fixed;
  z-index: 1071;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  user-select: none;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.evs_zoom_animated {
  transition: all 0.3s ease-in-out;
}

.evs-card {
  .icon-locked {
    display: none !important;
  }
}
.evs-card.evs-card-locked {
  .icon-container {
    img {
      opacity: 0.6;
    }
  }
  span,
  .text-container {
    color: var(--color-gray);
    font-weight: 500;
  }
  ion-icon {
    opacity: 0.6;
  }
}

*,
html,
.ios,
.md,
ion-app {
  --ion-default-font: 'Ubuntu' !important;
  font-family: 'Ubuntu' !important;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

b,
b > *,
.bold,
.bold > *,
strong,
strong > *,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

autocomplete-comp .evs_autocomplete,
autocomplete-comp .evs_autocomplete label {
  text-align: left !important;
}

// autocomplete-comp, autocomplete-local-comp {
//   display: block;
//   z-index: 1000;
//   position: relative;
// }

// this css is global for all application but specific to this student application
// ex : student-app as not the same css than teacher-app

#__lpform_registerPassword {
  pointer-events: none !important;
  display: none !important;
}

.faq-button {
  display: none;
  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 18px;
    right: 16px;
    z-index: 1000;
    cursor: pointer;
  }
}

.faq-icon {
  display: inline-flex;
  min-width: 24px;
  min-height: 24px;
  width: 24px;
  height: 24px;
  border: 2px solid var(--color-font);
  border-radius: 50%;
  background-image: url('assets/2022/icons/faq.svg');
  background-position: center;
  background-size: 35%;
  background-repeat: no-repeat;
}

.accordion-item-content {
  ul {
    padding-left: 16px;
  }
}

.infinite-rotating {
  transform: scaleX(-1) rotate(360deg);
  width: 12px !important;
  animation: rotating 2s linear infinite;
}

@keyframes rotating {
  from {
    transform: scaleX(-1) rotate(360deg);
  }
  to {
    transform: scaleX(-1) rotate(0deg);
  }
}

// MODAL MARKETING
// ---------------

ion-modal::part(content) {
  width: 80% !important;
  max-width: 768px !important;
  height: 80% !important;
  border-radius: 0.5em !important;
  overflow: hidden !important;
  @media screen and (max-height: 599px), screen and (max-width: 767px) {
    border-radius: 0 !important;
    width: var(--width) !important;
    max-width: var(--max-width) !important;
    height: var(--height) !important;
  }
}

// MODAL EMBEDED VIDEO

.embed-video-container {
  position: fixed;
  z-index: 2147483647; // max
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  .embed-video {
    max-width: 1008px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
    iframe {
      height: 100%;
      width: 100%;
    }
  }
  .close-btn {
    position: absolute;
    z-index: 10;
    top: 1em;
    right: 1em;
    @media screen and (max-width: 767px) {
      top: 8px;
      right: 8px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--color-neutral-100);
    width: 24px;
    height: 24px;
    box-shadow: 0px 0 4px rgba(0, 0, 0, 0.4);
    cursor: pointer;
  }
}

// BANDEAU PROMO DECONF
.pub-deconf {
  background: #ffe7b3;
  color: #ea5b0c;
  box-shadow: 0px 0.5px 2px var(--color-gray-62);
  margin-bottom: 20px;
  cursor: pointer;
  span {
    padding: 15px 10px;
    display: block;
    max-width: 600px;
    margin: auto;
    line-height: 1.3em;
    text-align: center;
  }
}

// BANDEAU PROMO SUMMER2020
.pub-summer2020 {
  background: #db3236;
  color: #e8f0fe;
  box-shadow: 0px 0.5px 2px var(--color-gray-62);
  margin-bottom: 20px;
  cursor: pointer;
  span {
    padding: 15px 10px;
    display: block;
    max-width: 600px;
    margin: auto;
    line-height: 1.3em;
    text-align: center;
    font-size: 17px;
    font-weight: 700;
  }
}
// MODAL IFRAME
// ------------

.custom-modal-iframe > ion-modal::part(content) {
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
  background: none !important;
  box-shadow: none !important;
  position: absolute !important;
}

.custom-modal-iframe {
  ion-modal::part(backdrop) {
    display: none !important;
  }
  ion-modal::part(content),
  .overscroll,
  .ios,
  .hydrated,
  .inner-scroll,
  .scroll-y {
    --overflow: hidden !important;
    overflow: hidden !important;
    overflow-y: hidden !important;
  }
}

// DASHBOARD PAGE
// --------------

.txt-pastConfirmedLesson {
  color: var(--color-green-300) !important;
}
.pastConfirmedLesson {
  background-color: var(--color-green-300) !important;
}

.evs-card-simple-dashboard,
.evs-card-simple-dashboard-mobile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  font-weight: 500;
  .card-nested-content {
    display: flex;
    align-items: center;
    line-height: 16px;
    width: 100%;
    ion-icon {
      color: var(--color-gray-62);
    }
    .icon-container {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      margin-right: 16px;
      @media screen and (max-width: 374px) {
        margin-right: 8px;
      }
      border-radius: 12px;
      overflow: hidden;
    }
    .fix-img-slider {
      width: initial !important;
      display: block !important;
      margin-left: auto !important;
      margin-right: 0 !important;
    }
  }
}
.evs-card-big-dashboard {
  display: flex;
  height: 200px;
  // background-color: var(--color-font);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: var(--color-white);
  @media screen and (max-width: 425px) {
    padding: 12px;
  }
  .card-nested-content,
  .card-content {
    line-height: 1.3em;
    width: 100%;
    padding: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-font);
    i {
      color: var(--color-orange-500);
    }
    @media screen and (max-width: 425px) {
      line-height: 1.2em;
      padding: 0;
    }
  }
}
.pub-in-app > .evs-card-big-dashboard {
  height: 150px !important;
}

// NEW PATTERNS FOR BIG CARDS

.evs-card-big-card {
  display: flex;
  height: 200px;
  background-size: 120% !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  color: var(--color-white);
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  .card-nested-content,
  .card-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    line-height: 1.3em;
    .btn {
      position: absolute;
      bottom: 16px;
      span {
        display: flex;
        align-items: center;
        img {
          display: inline-flex;
          width: 12px;
          margin-left: 8px;
        }
      }
    }
    .btn.light.white {
      color: var(--color-font);
    }
  }
}

.big-card-center {
  .evs-card-big-card {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .card-nested-content,
    .card-content {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      .btn {
        position: initial;
        margin: 24px auto 0;
      }
    }
  }
}

.evs-card-grey-pattern-1 {
  background: url('/assets/2022/patterns/big-card-grey-pattern-1.svg');
  background-color: var(--color-dark-500);
}
.evs-card-grey-pattern-2 {
  background: url('/assets/2022/patterns/big-card-grey-pattern-2.svg');
  background-color: var(--color-dark-500);
}
.evs-card-grey-pattern-3 {
  background: url('/assets/2022/patterns/big-card-grey-pattern-3.svg');
  background-color: var(--color-dark-500);
}
.evs-card-grey-pattern-4 {
  background: url('/assets/2022/patterns/big-card-grey-pattern-4.svg');
  background-color: var(--color-dark-500);
}
.evs-card-orange-pattern-1 {
  background: url('/assets/2022/patterns/big-card-orange-pattern-1.svg');
  background-color: var(--color-orange-500);
}
.evs-card-orange-pattern-1 {
  background: url('/assets/2022/patterns/big-card-orange-pattern-2.svg');
  background-color: var(--color-orange-500);
}
.evs-card-orange-pattern-3 {
  background: url('/assets/2022/patterns/big-card-orange-pattern-3.svg');
  background-color: var(--color-orange-500);
}
.evs-card-orange-pattern-4 {
  background: url('/assets/2022/patterns/big-card-orange-pattern-4.svg');
  background-color: var(--color-orange-500);
}
.evs-card-blue-pattern-1,
.evs-card-blue-pattern-2,
.evs-card-blue-pattern-4 {
  background-size: 150% !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background: url('/assets/2022/patterns/little-card-blue-pattern-1.svg');
  background-color: var(--color-blue-700);
}
.evs-card-blue-pattern-3,
.evs-card-blue-pattern-5 {
  background-size: 150% !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background: url('/assets/2022/patterns/little-card-blue-pattern-2.svg');
  background-color: var(--color-blue-700);
}
.evs-card-dark-blue-pattern-1,
.evs-card-dark-blue-pattern-5 {
  background-size: 150% !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background: url('/assets/2022/patterns/little-card-dark-blue-1.svg');
  background-color: var(--color-dark-700);
}
.evs-card-dark-blue-pattern-2,
.evs-card-dark-blue-pattern-3,
.evs-card-dark-blue-pattern-4 {
  background-size: 150% !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background: url('/assets/2022/patterns/little-card-dark-blue-2.svg');
  background-color: var(--color-dark-700);
}

// OLD PATTERNS

.evs-card-bg-1 {
  background-image: url(assets/svg/bg-white.svg);
  .card-nested-content,
  .card-content {
    // font-size: 18px;
    // font-weight: 700;
    margin-top: -30px;
    .callout {
      background: rgb(255, 255, 255);
      // border-radius: 8px;
      border-radius: 6px;
    }
  }
}
.evs-card-bg-2 {
  background: url('/assets/svg/code-home/Pattern.svg');
  background-position: 50% 40%;
  background-size: 150%;
  background-color: var(--color-hiorange);
  @media screen and (max-width: 767px) {
    background-position: 40% 60%;
    background-size: 300%;
  }
  .card-nested-content,
  .card-content {
    color: var(--color-white) !important;
    font-size: 18px;
    font-weight: 700;
    margin-top: -30px;
    .callout {
      border-radius: 6px;
    }
  }
}
.evs-card-bg-3 {
  background-image: url(assets/svg/bg-white2.svg);
  background-size: contain;
  background-repeat: repeat;
  .card-nested-content,
  .card-content {
    // font-size: 18px;
    // font-weight: 700;
    margin-top: -30px;
    .callout {
      background: rgb(255, 255, 255);
      // border-radius: 8px;
      border-radius: 6px;
      padding: 1em;
    }
  }
}
.evs-card-bg-4 {
  background-color: rgb(251, 136, 64);
  background-image: url(assets/svg/bg-orangeTint.svg);
  .card-nested-content,
  .card-content {
    color: var(--color-white) !important;
    // font-size: 18px;
    // font-weight: 700;
    margin-top: -30px;
    .callout {
      background: rgb(251, 136, 64);
      // border-radius: 8px;
      border-radius: 6px;
      padding: 1em;
    }
  }
}
.evs-card-bg-5 {
  background-image: url(assets/svg/bg-blue.svg);
  .card-nested-content,
  .card-content {
    // font-size: 18px;
    // font-weight: 700;
    margin-top: -30px;
    .callout {
      background: var(--color-blue-50);
      // border-radius: 8px;
      border-radius: 6px;
      padding: 1em;
    }
  }
}
.evs-card-bg-6 {
  background-image: url(assets/svg/bg-white3.svg);
  .card-nested-content,
  .card-content {
    // font-size: 18px;
    // font-weight: 700;
    margin-top: -30px;
    .callout {
      background: rgb(255, 255, 255);
      // border-radius: 8px;
      border-radius: 6px;
      padding: 1em;
    }
  }
}
.evs-card-bg-7 {
  background-image: url(assets/svg/bg-white.svg);
  .card-nested-content,
  .card-content {
    // font-size: 18px;
    // font-weight: 700;
    margin-top: -30px;
    .callout {
      background: rgb(255, 255, 255);
      // border-radius: 8px;
      border-radius: 6px;
      padding: 1em;
    }
  }
}
.evs-card-bg-8 {
  background-color: rgba(234, 91, 11, 0.9);
  background-image: url(assets/svg/bg-orange.svg);
  .card-nested-content,
  .card-content {
    color: var(--color-white) !important;
    // font-size: 18px;
    // font-weight: 700;
    margin-top: -30px;
    .callout {
      background: rgba(234, 91, 11, 0.9);
      // border-radius: 8px;
      border-radius: 6px;
      padding: 1em;
    }
  }
}
.evs-card-bg-9 {
  background-image: url(assets/svg/bg-white2.svg);
  .card-nested-content,
  .card-content {
    // font-size: 18px;
    // font-weight: 700;
    margin-top: -30px;
    .callout {
      background: rgb(255, 255, 255);
      // border-radius: 8px;
      border-radius: 6px;
      padding: 1em;
    }
  }
}
// .evs-card-bg-7 {
//   background-image: url(assets/svg/bigcard07.svg);
//   .card-nested-content, .card-content {
//     font-size: 18px;
//   }
// }
.evs-card-notif-dashboard {
  display: flex;
  align-items: center;
  height: 69px;
  border-left: 3px solid var(--color-success);
  .card-nested-content {
    line-height: 16px;
    width: 100%;
  }
}

$bgColor: #f0f5f8;
$margin: 16px;

.evs-card-cours-dashboard {
  display: flex;
  width: 140px;
  height: 150px;

  border-radius: 16px;
  background-color: var(--color-white);
  padding: 8px;
  margin-right: $margin;
  word-break: break-word;
  overflow: hidden;
  cursor: pointer;

  .card-nested-content {
    line-height: 16px;
    width: 100%;
  }

  .cdr-bg-bloc {
    height: 80px;
    border-radius: 12px;
    background-position: center;
    background-size: 100%;
  }
  .title-card {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    padding: 8px 8px 0 8px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    overflow-wrap: break-word;
    word-break: keep-all;
    @media screen and (max-width: 375px) {
      font-size: 12px;
    }

    .percent-progress {
      text-transform: initial;
    }
  }
}
.evs-card-cours-dashboard:hover {
  background-color: var(--color-neutral-50);
}

#customModalCalendar {
  .fix-button-access {
    width: calc(100% - 24px);
  }
  .icon-size-modal {
    font-size: 1.5em;
  }
}

#customModalCPFSubscription {
  .evs_modal_container {
    @media screen and (max-width: 767px) {
      width: 100% !important;
      height: 100% !important;
      max-width: 100% !important;
      max-height: 100% !important;
      border-radius: 0px !important;
    }
    .evs_modal_content {
      max-height: 100% !important;
      max-width: 100% !important;
      padding: 32px !important;
      margin: 0 !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 767px) {
        width: 100% !important;
        height: 100% !important;
        padding: 16px !important;
      }

      img {
        width: 200px;
        height: 200px;
        margin-bottom: 24px;
      }

      h4 {
        margin: 0 0 24px 0;
      }

      .icon-text-wrapper {
        display: flex;
        margin: 0 0 16px 0;
        p:first-child {
          margin: 0 16px 0 0;
        }
      }

      button {
        margin: 8px 0 0 0;
      }
    }
  }
}

#customModalDailyStreak {
  .evs_modal_container {
    max-width: 644px;
    width: 100%;
    @media screen and (max-width: 767px) {
      width: 100% !important;
      height: 100% !important;
      max-width: 100% !important;
      max-height: 100% !important;
      border-radius: 0px !important;
    }
    .evs_modal_content {
      max-height: 100% !important;
      max-width: 100% !important;
      padding: 32px !important;
      margin: 0 !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 767px) {
        width: 100% !important;
        height: 100% !important;
        padding: 34px 16px !important;
      }
      .close-btn {
        position: absolute;
        background-color: var(--color-neutral-100);
        border-radius: 50%;
        padding: 8px;
        width: 32px;
        height: 32px;
        top: 16px;
        right: 16px;
      }
      .calendar-headers-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 468px;
        width: 100%;
        .flamme {
          width: 150px;
          @media screen and (max-width: 767px) {
            width: 90px;
          }
        }
        .daily-streak-title {
          margin: 16px 0 12px 0;
          @media screen and (max-width: 767px) {
            margin: 8px 0;
          }
        }
        .daily-streak-desc {
          font-size: 14px;
          font-weight: 400;
          color: var(--color-neutral-500);
          margin: 0 0 12px 0;
        }
        .streak-record-wrapper {
          display: flex;
          width: 100%;
          gap: 8px;
          margin: 0 0 12px 0;
          @media screen and (max-width: 767px) {
            margin: 8px 0;
          }
          .streak-record {
            border-radius: 14px;
            border: 1px solid var(--color-neutral-300);
            display: flex;
            padding: 8px;
            width: 100%;
            align-items: center;
            img {
              max-width: 24px;
              max-height: 24px;
              margin: 0 8px 0 0;
            }
            div {
              display: flex;
              flex-direction: column;
              p:first-child {
                font-size: 10px;
                font-weight: 400;
                color: var(--color-neutral-500);
                margin: 0;
              }
              p:last-child {
                font-size: 12px;
                font-weight: 500;
                color: var(--color-font);
                margin: 0;
              }
            }
          }
        }
      }
      .calendar-wrapper {
        width: 100%;
        @media screen and (max-width: 767px) {
          max-width: 468px;
        }
      }
    }
  }
}

.without-cordova {
  .evs_modal_container {
    margin-top: 80px !important;
    @media screen and (max-width: 767px) {
      height: calc(100% - 72px) !important;
      top: 72px;
      margin-top: 0px !important;
    }
  }
}
#customModalMap {
  .evs_modal_container {
    color: var(--color-font);
    max-width: 600px !important;
    @media screen and (max-width: 767px) {
      width: 100% !important;
      height: 100%;
      max-width: 100% !important;
      max-height: 100%;
      border-radius: 0px !important;
    }
    .evs_modal_close_btn {
      z-index: 6001 !important;
    }
    .evs_modal_content {
      max-height: 100% !important;
      max-width: 100% !important;
      padding: 32px !important;
      margin: 0 !important;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 767px) {
        width: 100% !important;
        height: 100% !important;
        padding: 16px !important;
      }
      .close-modal-button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        width: 32px;
        height: 32px;
        margin: -20px -20px 0 auto;
        @media screen and (max-width: 767px) {
          margin: -8px -8px 0 auto;
        }
      }
      .map-text {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        color: var(--color-font);
        padding: 0 32px 20px 32px;
        text-align: center;
        @media screen and (max-width: 767px) {
          font-size: 18px;
          line-height: 24px;
          padding: 0 24px 20px 24px;
        }
      }
      .map-km {
        text-align: center;
        background-color: #fdeced;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: center;
        padding: 16px;
        margin: 0 0 16px 0;

        p {
          margin: 0;
        }

        .map-city {
          text-align: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          p {
            margin: 0;
            font-size: 16px;
            font-weight: 700;
            color: #2a2d34;
          }
          img {
            width: 18px;
            height: 18px;
            padding-right: 5px;
            margin-top: 1px;
          }
        }
      }
      .map-input {
        margin: 24px 0 16px 0;
        form {
          position: relative;
          display: flex;
          width: 100%;
          label {
            left: 16px !important;
            min-height: 16px !important;
            line-height: 16px !important;
            top: 20px !important;

            &.label-up {
              font-size: 12px !important;
            }
          }
        }
        #geoloc-input {
          width: 100%;
          height: 56px;
          outline: none;
          border: 1px solid var(--color-neutral-100);
          padding: 28px 78px 10px 16px;
          border-radius: 16px;
          font-size: 14px;
          font-weight: 500;
        }
        .delete-btn {
          position: absolute;
          top: 17px;
          right: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          filter: grayscale(100%) brightness(1.5);
          cursor: pointer;
          img {
            width: 16px;
            height: 16px;
          }
        }
        .geoloc-btn {
          width: 20px;
          height: 20px;
          position: absolute;
          top: 17px;
          right: 16px;
          margin: 0;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
          .btn-spinner {
            width: 20px;
            height: 20px;
            // color: black;
          }
        }
        .geoloc-btn {
          width: 20px;
          height: 20px;
          position: absolute;
          top: 17px;
          right: 16px;
          margin: 0;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
          .btn-spinner {
            width: 20px;
            height: 20px;
            // color: black;
          }
        }
      }
      .map {
        width: 100%;
        height: 210px;
        border-radius: 16px;
        @media screen and (max-width: 767px) {
          @media screen and (min-height: 730px) {
            height: 370px;
          }
          height: 268px;
        }
      }
      .map-cta {
        margin: auto 0 0 0;
      }
      .gray-map {
        filter: grayscale(1);
      }
    }
  }
}

#customModalCreditQuestion {
  .evs_modal_container {
    color: var(--color-font);
    @media screen and (max-width: 767px) {
      width: 100% !important;
      height: 100% !important;
      max-width: 100% !important;
      max-height: 100% !important;
      border-radius: 0px !important;
    }
    .evs_modal_content {
      max-height: 100% !important;
      max-width: 100% !important;
      padding: 32px !important;
      margin: 0 !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media screen and (max-width: 767px) {
        width: 100% !important;
        height: 100% !important;
        padding: 16px !important;
      }
      .close-modal-button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        position: absolute;
        right: 16px;
        top: 16px;
        background-color: var(--color-neutral-100);
      }
      h5 {
        margin: 0 0 32px 0;
        color: var(--color-black);
      }
      p {
        margin: 0;
      }
      ul {
        margin: 24px 0;
        li:not(:last-child) {
          margin: 0 0 24px 0;
        }
      }
    }
  }
}

// CODE-HOME PAGE + CONDUITE PAGE
// --------------

.media-parent-container {
  cursor: pointer;
}
.media-parent-container.media-fullscreen {
  position: fixed;
  z-index: 2147483647;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  .close-btn {
    position: absolute;
    top: 1em;
    right: 1em;
    z-index: 1080;
  }
  .media-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gif-comp {
      width: 100%;
    }
    img,
    .video,
    .gif-container {
      border-radius: 0 !important;
    }
    .video {
      top: initial !important;
      left: initial !important;
      width: 100%;
    }
  }
}

convocation-page,
conduite-page,
code-home-page {
  ion-content {
    --ion-background-color: var(--color-blue-50);
  }
  card-comp {
    display: inline-block;
    width: calc(50% - 4px);
    margin: 0;
    @media screen and (max-width: 767px) {
      display: block;
      width: 100%;
    }
  }
  // .evs-card-conduite-card-shimmer,
  // .evs-card-code-home-card-shimmer,
  // .evs-card-conduite-card-shimmer,
  // .evs-card-code-home-card-shimmer {
  //   position: relative;
  //   overflow: hidden;
  //   display: inline-flex;
  //   width: calc(50% - 4px);
  //   margin-bottom: 8px;
  //   @media screen and (max-width: 767px) {
  //     display: flex;
  //     width: 100%;
  //   }
  // }
  // .evs-card-conduite-card-shimmer:nth-child(odd),
  // .evs-card-code-home-card-shimmer:nth-child(odd) {
  //   margin-right: 8px;
  //   @media screen and (max-width: 767px) {
  //     margin-right: 0;
  //   }
  // }
  @media screen and (max-width: 767px) {
    #customModalDossierExam1,
    #customModalDossierExam2 {
      .evs_modal_content {
        height: auto;
        max-height: fit-content;
        button {
          white-space: pre-wrap;
        }
      }
    }
  }
}

code-home-page {
  card-comp {
    &.card-comp-neph-infos {
      width: 100%;
      margin: 24px 0 0 0;
    }
  }
}

conduite-page {
  card-comp {
    &.card-comp-neph-infos {
      width: 100%;
      margin: 0 0 24px 0;
    }
  }
}

.evs-card-conduite-card,
.evs-card-code-home-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 600px;
  margin: auto;
  padding: 8px;
  .icon-container {
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 12px;
    overflow: hidden;
    margin-right: 16px;
    @media screen and (max-width: 374px) {
      margin-right: 8px;
    }
  }
  .text-container.show-credits {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .card-content {
    display: flex;
    align-items: center;
    .arrow {
      position: absolute;
      right: 8px;
    }
    ion-spinner.arrow {
      position: absolute;
      right: 20px;
      color: var(--color-neutral-500);
      zoom: 0.8;
    }
  }
  .text-container {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 75px);
    height: 50px;
    font-weight: 500;
  }
  .text-container.two-lines {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .sub-title {
      color: var(--color-gray-62);
      font-size: 14px;
      margin-top: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }
}

.evs-card-code-home-card-neph-infos,
.evs-card-conduite-card-neph-infos {
  max-width: 600px;
  margin: auto;
  padding: 16px;
  background-color: var(--color-dark-700);
  color: var(--color-white);
  .card-content {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    width: 100%;
  }
}

.evs-card-code-home-card-dailystreak {
  display: flex;
  max-width: 600px;
  margin: auto;
  padding: 16px;
  flex-direction: column;
  .card-content {
    width: 100%;
  }
  .text-container {
    font-weight: 500;
  }
  .dailystreak-container {
    display: flex;
    flex-direction: row;
    margin: 8px 0 0 0;
    .days-container {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      padding: 4px 0 0 0;
      justify-content: space-between;
      margin: 0 16px 0 0;
      .day-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .streak-indicator-active {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: var(--color-orange-500);
          padding: 4px;
          margin: 0 0 2px 0;
        }
        .streak-indicator-inactive {
          border: 2px solid var(--color-orange-500);
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin: 0 0 2px 0;
        }
        p {
          font-size: 12px;
          font-weight: 400;
          color: var(--color-font);
          margin: 0;
          &.text-streak-active {
            font-weight: 700;
          }
        }
        .active-indicator {
          background-color: var(--color-orange-500);
          border-radius: 50%;
          width: 4px;
          height: 4px;
        }
      }
    }
    .streak-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      border: 3px solid var(--color-orange-500);
      border-radius: 50%;
      p {
        font-size: 14px;
        font-weight: 700;
        color: var(--color-font);
        margin: 0;
      }
      .flame-streak {
        position: absolute;
        bottom: -3px;
        right: -3px;
        max-height: 20px;
        max-width: 17px;
      }
    }
  }
}

$bigCardConduiteSize: 226px;
$bigCardConduiteBgSize: 210px;

$bigCardConduiteSizeMobile: 135px;

.evs-card-big-conduite {
  position: relative;
  padding: 0;
  height: $bigCardConduiteSize;
  @media screen and (max-width: 465px) {
    height: $bigCardConduiteSizeMobile;
  }
  overflow: hidden;

  .card-nested-content {
    position: relative;
    overflow: hidden;
  }
  .big-card-conduite-bg {
    position: relative;
    z-index: 1;
    left: -70px;
    top: -10px;
    width: $bigCardConduiteBgSize;
    height: $bigCardConduiteBgSize;
    transform: scaleX(-1) rotate(-30deg);
    transform-origin: center;
    background: url('assets/svg/asset-permis.svg');
    background-size: $bigCardConduiteBgSize;
    background-repeat: no-repeat;
    @media screen and (max-width: 465px) {
      background-size: 150px;
      left: -110px;
      top: -25px;
    }
  }
  .big-card-conduite {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 20px;
    width: calc(100% - 200px);
    height: $bigCardConduiteSize;
    @media screen and (max-width: 465px) {
      width: calc(100% - 150px);
      height: $bigCardConduiteSizeMobile;
    }
    display: flex;
    justify-content: center;
    flex-direction: column;
    h3 {
      margin: 0;
      margin-bottom: 10px;
      @media screen and (max-width: 465px) {
        font-size: 18px;
      }
    }
    p {
      margin: 0;
      padding: 0;
      color: var(--color-gray-62);
      @media screen and (max-width: 465px) {
        font-size: 14px;
      }
    }
  }
}

.conduite-prospect {
  background: url('assets/svg/conduite/comment-ca-marche.svg');
  background-size: cover;
  background-repeat: no-repeat;
}

#customModalVideo {
  .evs_modal_bg {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .evs_modal_container {
    max-width: 640px !important;
    .evs_modal_content {
      margin: 0 !important;
      // margin: 0!important;
      max-height: initial !important;
    }
  }
}
.video-youtube {
  position: relative;
  video {
    position: relative;
    display: block !important;
    margin: 0 !important;
    border-radius: 0 !important;
  }
}

.slider-conduite {
  .slider {
    .dirslider_btLeft,
    .dirslider_btRight {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .item {
      position: relative;
      will-change: transform;
      img {
        position: absolute;
        top: 14px;
        @media screen and (max-width: 767px) {
          width: 64px;
        }
      }
      .txt {
        position: absolute;
        bottom: 12px;
        line-height: 16px;
      }
    }
  }
}

.slider-exam-reservation,
.eligibilite-cpf {
  background-color: #fff;
  .slider {
    background-color: #fff;
    text-align: center;
    @media screen and (max-width: 767px) {
      text-align: initial;
    }

    .dirslider_btLeft,
    .dirslider_btRight {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    // .dirslider_divSlides {
    //   width: 540px!important;
    // }
    .step-container {
      display: inline-block;
      padding: 16px 32px;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      @media screen and (max-width: 767px) {
        padding: 12px 8px !important;
      }
      .step {
        position: relative;
        will-change: transform;
        display: flex;
        align-items: center;
        img {
          width: 32px;
          height: 32px;
          padding: 8px;
          border-radius: 50%;
        }
        .txt {
          margin-left: 8px;
          font-size: 16px;
          font-weight: 400;
          @media screen and (max-width: 767px) {
            font-size: 12px;
            font-weight: 500;
          }
        }
        &.done {
          img {
            background-color: #a1e5d3;
          }
          .txt {
            color: #009d76;
          }
        }
        &.ongoing {
          img {
            background-color: #c5d4ec;
          }
          .txt {
            color: #494e5a;
          }
        }
        &.inactive {
          img {
            background-color: #e5e7ea;
          }
          .txt {
            color: #919395;
          }
        }
      }
    }
  }
}

// fix black screen force play
conduite-page {
  display: block;
  position: relative;
  modal-comp {
    display: block;
    position: fixed;
    z-index: 6000;
    .evs_modal_close_btn {
      z-index: 1;
    }
    video-comp {
      display: block;
      position: relative;
    }
  }
}

.evs-card-big-conduite.promo-happygift {
  .big-card-conduite-bg {
    height: 220px;
    background-image: url('assets/svg/asset-promo.svg');
    background-size: 160px;
    transform: rotate(10deg);
    background-repeat: no-repeat;
    left: -20px;
    top: 0;
  }
  .big-card-conduite {
    width: calc(100% - 210px);
    @media screen and (max-width: 465px) {
      width: calc(100% - 180px);
      height: $bigCardConduiteSizeMobile;
    }
    h3 {
      del {
        font-size: 18px;
        font-weight: normal;
        @media screen and (max-width: 465px) {
          font-size: 13px;
        }
      }
    }
  }
}

// STATISTIQUES
// --------------

.bloc-stat-thematiques {
  .pie-wrapper {
    margin: 0 20px 0 0 !important;
    @media screen and (max-width: 767px) {
      margin: 0 10px 0 0 !important;
    }
  }
}

// SERIES LIST PAGE
// --------------

.evs-radial-percent svg circle.active {
  stroke: var(--color-orange-500) !important;
}

// .series-list-container {
//   loader-bar-comp {
//     .evs-progress-bar, .evs-progress-bar > .progress-bar {
//       border-radius: 2px !important;
//       height: 10px !important;
//     }
//   }
// }

.downloading-icon {
  .evs-radial-percent {
    svg {
      circle:first-child {
        stroke-width: 6px !important;
      }
      circle:last-child {
        stroke-width: 4px !important;
      }
    }
  }
}

// SERIE PAGE
// --------------
.serie-enable-user-select {
  user-select: text;
}

.serie-container,
.serie-results-container {
  progress-bar-comp {
    .evs-progress-bar {
      background-color: var(--color-success-tint) !important;
    }
    .evs-progress-bar > .progress-bar {
      background-color: var(--color-font) !important;
    }
    .evs-progress-bar,
    .evs-progress-bar > .progress-bar {
      border-radius: 0 !important;
      height: 6px !important;
    }
  }
}
.serie-results-container {
  .evs-card {
    @media screen and (max-width: 767px) {
      background-color: var(--color-white);
    }
  }
}
.evs-card.content-serie-results {
  @media screen and (max-width: 767px) {
    background-color: var(--color-white);
  }
}
// shadow
// $shadow: 0 2px 6px 0 rgba(0, 0, 0, .2);
// $shadowHover: 0 2px 6px 0 rgba(0, 0, 0, .4);
$shadow:
  0px 0px 3px rgba(0, 0, 0, 0.1),
  0px 3px 6px rgba(0, 0, 0, 0.1);
$shadowHover:
  0px 0px 3px rgba(0, 0, 0, 0.3),
  0px 3px 6px rgba(0, 0, 0, 0.3);

.ico-good-answer,
.ico-wrong-answer {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background-position: center;
  background-size: 40%;
  background-repeat: no-repeat;
}
.ico-good-answer {
  background-color: rgba(161, 229, 211, 0.5);
  background-image: url(assets/2022/icons/tick-green.svg);
}
.ico-wrong-answer {
  background-color: rgba(251, 208, 211, 0.5);
  background-image: url(assets/2022/icons/cross-red.svg);
}

.questions-container,
.answers-container {
  .evs-loading-btn {
    height: 52px;
    overflow: hidden;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
    // font-weight: bold;
  }
  label {
    display: flex;
    align-items: center;
    padding: 8px;
    height: 55px;
    position: relative;
    cursor: pointer;

    svg {
      color: var(--color-success-tint);
      // font-weight: 700;
      // font-size: 2em;
      position: absolute;
      right: 0.5em;
    }
    img {
      position: absolute;
      right: 0.8em;
      top: 1.1em;
    }
    .answer-icon {
      position: absolute;
      right: 0.8em;
      top: initial;
    }
    .badAnswer {
      color: var(--color-info);
    }
    .value-label {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;
      min-height: 40px;
      width: 40px;
      height: 40px;
      margin-right: 16px;
      background-color: var(--color-neutral-100);
      border-radius: 8px;
      @media screen and (max-width: 767px) {
        margin-right: 8px;
      }
      font-size: 18px;
      font-weight: bold;
    }
    .value-text {
      font-weight: 500;
    }
    .value-text-with-icon {
      font-weight: 500;
      width: calc(100% - 90px);
      @media screen and (max-width: 767px) {
        width: calc(100% - 82px);
      }
    }
  }
  label:hover {
    border: 2px solid var(--color-neutral-400);
  }
  input[type='checkbox']:not(:checked) + label.no-hover:hover {
    @media screen and (max-width: 767px) {
      border: none;
    }
  }
  input[type='checkbox'] + label {
    font-weight: 400;
  }
  input[type='checkbox']:checked + label,
  .selected {
    border: 2px solid var(--color-orange-500);
    .value-label {
      background: var(--color-orange-100);
      color: var(--color-orange-500);
    }
  }
  .not-selected {
    border: 2px solid var(--color-neutral-300) !important;
    .value-label {
      color: var(--color-main-text) !important;
      background-color: var(--color-neutral-100) !important;
    }
  }
  .no-hover.selected {
    // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.1) !important;
  }
  .goodAnswer,
  .goodAnswer:hover {
    border-color: var(--color-green-300);
    .value-label {
      background: var(--color-green-300);
      color: var(--color-white);
    }
  }
  .wrongAnswer,
  .wrongAnswer:hover {
    border-color: var(--color-orange-500);
    .value-label {
      background: var(--color-orange-500);
      color: var(--color-white);
    }
  }
  .no-click {
    cursor: initial;
  }
}

expand-results-comp {
  .collapsible-title {
    span {
      font-weight: 500;
    }
  }
  .answers-container {
    label {
      img {
        top: initial;
      }
    }
  }
}

// CUSTOM MODALS
#customModalEndFirstSerie,
#customModalSound,
#customModalStrike5,
#customModalStrike10,
#customModalQuitExam {
  .evs_modal_content {
    ion-icon {
      font-size: 6em;
    }
  }
}

#customModalMajCode2023 {
  .evs_modal_content {
    margin: 0;
    text-align: center;
    max-height: 335px;
    padding-right: 0;
  }
  .title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .text {
    font-size: 16px;
    font-weight: 400;
  }
  .text-box {
    margin: 24px 16px 32px 16px;
  }
  .button {
    margin: 0 16px 16px 16px;
  }
}

#customModalDeleteSession {
  .evs_modal_content {
    max-height: max-content;
  }
}

#customModalAcceptLesson {
  .evs_modal_container {
    max-width: 600px;
    max-height: calc(100% - 32px);
    overflow: auto;
    position: initial;
    @media screen and (max-width: 767px) {
      box-shadow: none;
      max-width: 100%;
      max-height: 100%;
      top: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      right: 0 !important;
      border-radius: 0;
    }
    .evs_modal_content {
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
      max-height: calc(100% - 32px);
      .header {
        position: relative;
        padding: 8px 0 16px 8px;
        border-bottom: 1px solid var(--color-gray-74);
        margin-bottom: 16px;
        span {
          font-size: 22px;
          font-weight: 700;
          color: var(--color-success);
        }
        div {
          position: absolute;
          display: inline-block;
          opacity: 0.5;
          right: 0;
          z-index: 1;
          cursor: pointer;
        }
      }
      .content {
        div {
          padding: 6px 8px;
          @media screen and (max-width: 767px) {
            padding: 4px 8px;
          }
        }
        ul,
        li {
          line-height: 1.2em;
          padding: 0 0 0 16px;
        }
        ul {
          margin-bottom: 8px;
        }
        li {
          padding: 0 0 6px 0;
          margin: 0;
        }
        li:last-child {
          padding: 0;
        }
        .checkboxes {
          padding: 0;
          cursor: pointer;
          span:first-child {
            width: 25px;
            text-align: left;
            cursor: pointer;
          }
          span {
            width: calc(100% - 30px);
            cursor: pointer;
          }
        }
        .controls {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px 0;
        }
        .controls {
          button:first-child {
            margin-right: 16px;
          }
        }
        .lesson-driving-type {
          font-size: 14px;
          font-weight: 400;
          padding: 4px 16px;
          border-radius: 50px;
          &.automatic {
            color: #0098ff;
            background-color: #e3f3ff;
          }
          &.manual {
            color: var(--color-orange-500);
            background-color: rgba(255, 203, 184, 0.35);
          }
        }
      }
    }
  }
}

#customModalRefuseLesson,
#customModalCancelLesson {
  .evs_modal_container {
    .evs_modal_content {
      max-height: max-content;
      .header {
        position: relative;
        padding: 8px 0 16px 8px;
        border-bottom: 1px solid var(--color-gray-74);
        margin-bottom: 16px;
        span {
          font-size: 22px;
          font-weight: 700;
          color: var(--color-danger);
        }
        div {
          position: absolute;
          display: inline-block;
          opacity: 0.5;
          right: 0;
          z-index: 1;
          cursor: pointer;
        }
      }
      .content {
        div {
          padding: 6px 8px;
          @media screen and (max-width: 767px) {
            padding: 4px 8px;
          }
        }
        ul,
        li {
          line-height: 1.2em;
          padding: 0 0 0 16px;
        }
        ul {
          margin-bottom: 8px;
        }
        li {
          padding: 0 0 6px 0;
          margin: 0;
        }
        li:last-child {
          padding: 0;
        }
        .checkboxes {
          padding: 0;
          cursor: pointer;
          span:first-child {
            width: 25px;
            text-align: left;
            cursor: pointer;
          }
          span {
            width: calc(100% - 30px);
            cursor: pointer;
          }
        }
        .controls {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px 0;
        }
        .controls {
          button:first-child {
            margin-right: 16px;
          }
        }
        .lesson-driving-type {
          font-size: 14px;
          font-weight: 400;
          padding: 4px 16px;
          border-radius: 50px;
          &.automatic {
            color: #0098ff;
            background-color: #e3f3ff;
          }
          &.manual {
            color: var(--color-orange-500);
            background-color: rgba(255, 203, 184, 0.35);
          }
        }
      }
    }
  }
}

#customModalCancelLesson {
  .evs_modal_container {
    .evs_modal_content {
      .header {
        width: calc(100% - 24px);
        span {
          color: var(--color-font);
        }
      }
      .content {
        .controls {
          button:first-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

// #customModalJustifSavoirPlus {
//   @media screen and (max-width: 767px) {
//     .evs_modal_container {
//       box-shadow: none;
//       max-width: 100%;
//       max-height: 100%;
//       top: 0!important;
//       bottom: 0!important;
//       left: 0!important;
//       right: 0!important;
//       border-radius: 0;
//       .evs_modal_close_btn {
//         display: none!important;
//         margin: 24px;
//         background-color: transparent;
//         border-radius: 0;
//         border: none;
//         padding: 0;
//         color: transparent;
//         box-shadow: none;
//         ion-icon {
//           color: #999;
//           width: 32px;
//           height: 32px;
//         }
//       }
//       .evs_modal_content {
//         max-height: 100%;
//         // padding-top: 24px;
//         padding-bottom: 32px;
//       }
//     }
//   }
// }

// RESULTS PAGE
// --------------

confettis-comp {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1002;
}

// PROFIL PAGE
// --------------

.profil-small-cards {
  card-comp {
    margin-bottom: 8px;
    .card-content {
      width: 100%;
      .gray-chevron {
        margin-right: 8px;
      }
    }
  }
  card-comp:last-child {
    margin-bottom: 0;
  }
}

.evs-card-edit-profil {
  display: flex;
  align-items: center;
  padding-left: 20px;
  .first-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 400px;
    line-height: 1.3em;
    @media screen and (max-width: 767px) {
      width: 150px;
    }
  }
}
.evs-card-profil-simple-card,
.evs-card-profil-simple-card-2 {
  display: flex;
  align-items: center;
  .card-content {
    display: flex;
    align-items: center;
  }
}

.evs-card-profil-square-card {
  .card-content {
    width: 100% !important;
    img {
      width: 100%;
    }
  }
}
.evs-card-profil-simple-card-2 {
  .icon-container {
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    height: 60px;
  }
}
.evs-card-profil-simple-card {
  padding: 8px;
  .card-content {
    display: flex;
    align-items: center;
    .icon-container {
      width: 50px;
      height: 50px;
      border-radius: 12px;
      overflow: hidden;
      margin-right: 16px;
      @media screen and (max-width: 374px) {
        margin-right: 8px;
      }
      img {
        width: 100%;
      }
    }
    span {
      font-weight: 500;
    }
  }
}

// .device-decal-modal-bottom {
//   #customModalUpload3 {
//     margin-top: -60px;
//   }
// }

.evs_modal_container {
  border-radius: 16px !important;
  .evs_modal_close_btn {
    background-color: none !important;
    border: none !important;
    border-radius: none !important;
    box-shadow: none !important;
    right: 12px !important;
    top: 12px !important;
    width: 24px !important;
    height: 24px !important;
    background: url(assets/2022/icons/close-off.svg) !important;
    background-size: 100% !important;
    ion-icon {
      display: none !important;
    }
  }
}

#customModalUpload3 {
  width: 100%; // override small modal to full mobile screen width
  margin: auto;
  cursor: pointer;

  .evs_modal_container {
    width: 100%; // override small modal to full mobile screen width
    max-width: calc(
      100% - 2em
    ); // override small modal to full mobile screen width
    border-radius: 1em;
    overflow: hidden;

    .evs_modal_close_btn {
      display: none !important;
    }

    .evs_modal_content {
      max-height: inherit;
      overflow: inherit;
      height: max-content;
      margin: 0;
      padding: 0;

      .uploadMobileChoice {
        font-weight: 700;
        color: #808080;
        font-size: 1.2em;
        padding: 1.2em 0;
      }
      .uploadMobileChoice:hover {
        background-color: darken(white, 20);
      }
      .uploadMobileChoice:first-child {
        border-bottom: 1px solid #808080;
      }
    }
  }
}

// NOS OFFRES
// ----------

.fix-line-height {
  @media screen and (max-width: 767px) {
    p {
      line-height: 1.3;
    }
  }
}

#customModalExamConduite {
  .fix-mobile {
    white-space: pre-wrap;
    @media screen and (max-width: 400px) {
      margin-right: 10px;
    }
  }
}

// MAYBE TEMP : replace by uikit or component scss
// -----------------------------------------------

$card-shadow:
  0px 0px 3px rgba(0, 0, 0, 0.1),
  0px 3px 6px rgba(0, 0, 0, 0.1);

.responsive-rounded-container {
  display: block;
  max-width: 600px;
  box-shadow: $card-shadow;
  // border-radius: 8px;
  border-radius: 6px;
  padding: 10px !important;
  margin: 20px auto;
  margin-bottom: 3em;
  position: relative;
}

.responsive-rounded-container950 {
  max-width: 950px;
}

@media screen and (max-width: 767px) {
  .responsive-rounded-container {
    max-width: 768px;
    box-shadow: none;
    border-radius: 0;
    padding: 0 !important;
    margin: 0 auto;
  }
}

// **********************************
// *       MAP DES DISPOS           *
// **********************************

points-de-rendez-vous-page {
  bloc-header-comp {
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }
}

// **********************************
// *   CUSTOM CSS FOR COMPONENTS    *
// **********************************

#customModalDebugMode {
  .alert-info {
    display: inline-block;
    background: red;
    color: var(--color-white);
    border-radius: 8px;
    padding: 8px;
    margin-top: 10px;
  }
}

#customModalNoResults,
#customModalNoGeoloc {
  .evs_modal_close_btn {
    display: none !important;
  }
}

#customModalNoResults {
  .evs_modal_container {
    max-width: 320px !important;
  }
}

#customModal3 {
  .evs_modal_bg {
    background-color: rgba(255, 200, 0, 0.9);
  }
  .evs_modal_header {
    font-size: 1.4em;
    background-color: red;
    border-bottom: 1px solid darken(red, 20%);
    color: yellow;
  }
  .evs_modal_content {
    font-size: 1.1em;
    line-height: 1.4em;
  }
  .evs_modal_footer {
    background-color: orange;
    border-top: 1px solid darken(orange, 20%);
    .ion-color-medium {
      --ion-color-base: red !important;
    }
  }
}

#paginator1 {
  background-color: #26272c;
  padding: 2em 2em 1em 2em;
  text-align: center;
  border-radius: 6px;
  -webkit-box-shadow: 0 1px rgba(255, 255, 255, 0.1);
  box-shadow: 0 1px rgba(255, 255, 255, 0.1);
  .evs_pagination_content {
    .content {
      border-color: lightgray;
      text-align: left;
    }
  }
  .evs_pagination {
    padding: 8px;
    background-clip: padding-box;
    border: 1px solid;
    border-color: #070809 #0d0e0f #131517;
    border-color: rgba(0, 0, 0, 0.8) rgba(0, 0, 0, 0.65) rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    display: inline-flex;
    margin: auto;
    background-image: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0.12),
      rgba(0, 0, 0, 0)
    );
    background-image: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0.12),
      rgba(0, 0, 0, 0)
    );
    background-image: -o-linear-gradient(
      top,
      rgba(0, 0, 0, 0.12),
      rgba(0, 0, 0, 0)
    );
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.12),
      rgba(0, 0, 0, 0)
    );
    -webkit-box-shadow: 0 1px rgba(255, 255, 255, 0.1);
    box-shadow: 0 1px rgba(255, 255, 255, 0.1);
    .item {
      margin-left: 5px;
      min-width: 35px;
      background-color: transparent;
      text-decoration: none;
      text-shadow: 0 1px black;
      background-clip: padding-box;
      border-color: rgba(0, 0, 0, 0.9);
      -webkit-box-shadow:
        inset 0 0 0 1px rgba(255, 255, 255, 0.08),
        inset 0 1px rgba(255, 255, 255, 0.08),
        inset 0 -1px rgba(0, 0, 0, 0.3),
        0 1px 1px rgba(0, 0, 0, 0.2);
      box-shadow:
        inset 0 0 0 1px rgba(255, 255, 255, 0.08),
        inset 0 1px rgba(255, 255, 255, 0.08),
        inset 0 -1px rgba(0, 0, 0, 0.3),
        0 1px 1px rgba(0, 0, 0, 0.2);
      -webkit-transition: 0.1s ease-out;
      -moz-transition: 0.1s ease-out;
      -o-transition: 0.1s ease-out;
      transition: 0.1s ease-out;
      .link.active {
        color: #eee;
        text-shadow: 0 -1px black;
        background: #1c1c1c;
        background: rgba(255, 255, 255, 0.02);
        border-color: black rgba(0, 0, 0, 0.85) rgba(0, 0, 0, 0.8);
        box-shadow:
          inset 0 2px rgba(0, 0, 0, 0.1),
          inset 0 2px 4px rgba(0, 0, 0, 0.6),
          0 1px rgba(255, 255, 255, 0.2);
      }
      .link {
        background-image: -webkit-linear-gradient(
          top,
          rgba(255, 255, 255, 0.04),
          rgba(255, 255, 255, 0)
        );
        background-image: -moz-linear-gradient(
          top,
          rgba(255, 255, 255, 0.04),
          rgba(255, 255, 255, 0)
        );
        background-image: -o-linear-gradient(
          top,
          rgba(255, 255, 255, 0.04),
          rgba(255, 255, 255, 0)
        );
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.04),
          rgba(255, 255, 255, 0)
        );
        padding: 0 6px;
        min-width: 17px;
        line-height: 27px;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 12px;
        font-weight: 700;
        color: #d4d4d4;
        text-align: center;
        text-decoration: none;
        border: 1px solid rgba(0, 0, 0, 0.4);
      }
    }
  }
}

#customCollapsible1 {
  display: inline-block;
  text-decoration: none;
  color: var(--color-white);
  font-weight: 700;
  background-color: #538fbe;
  font-size: 24px;
  border: 1px solid #2d6898;
  background-image: linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -o-linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -moz-linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -webkit-linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -ms-linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, rgb(73, 132, 180)),
    color-stop(1, rgb(97, 155, 203))
  );
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.5);
  box-shadow:
    0px 6px 0px #2b638f,
    0px 3px 15px rgba(0, 0, 0, 0.4),
    inset 0px 1px 0px rgba(255, 255, 255, 0.3),
    inset 0px 0px 3px rgba(255, 255, 255, 0.5);

  .evs_collapsible-content {
    font-size: 18px;
  }
  .evs_collapsible-content-inner {
    padding: 18px 20px;
  }
  .evs_collapsible-title {
    padding: 18px 20px;
  }
  .evs_collapsible-title::after {
    right: 1em;
    border-width: 12px;
  }
  .evs_collapsible-content {
    p:last-child {
      color: yellow;
    }
  }
}

#ciblage1,
#ciblage2 {
  .evs_collapsible-content-inner {
    padding: 1em;
    // border-radius: 8px;
    border-radius: 6px;
    border: 3px solid lightgray;
  }
}

#breadcrumb1 {
  display: inline-block;
  margin: 0;
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
  background-color: #d4f2ff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);

  .evs_breadcrumb-separator {
    display: none;
  }

  a,
  .evs_breadcrumb-current {
    height: 40px;
    background: #3498db;
    text-align: center;
    position: relative;
    padding: 0 30px 0 40px;
    margin: 0 5px 0 0;
    font-size: 18px;
    text-decoration: none;
    color: var(--color-white);
    font-weight: 700;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  a:after,
  .evs_breadcrumb-current:after {
    content: '';
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #3498db;
    position: absolute;
    right: -20px;
    top: 0;
    z-index: 1;
  }

  a:before,
  .evs_breadcrumb-current:before {
    content: '';
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #d4f2ff;
    position: absolute;
    left: 0;
    top: 0;
  }

  a:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  a:first-child:before {
    display: none;
  }

  .evs_breadcrumb-current {
    margin-right: 0;
    padding-right: 40px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .evs_breadcrumb-current:after {
    display: none;
  }

  a:hover {
    background: #fa5ba5;
  }

  a:hover:after {
    border-left-color: #fa5ba5;
  }
}

.customForms {
  .uploadBtn {
    display: block;
    border: 2px solid gray;
    color: gray;
    background-color: var(--color-white);
    padding: 8px 20px;
    // border-radius: 8px;
    border-radius: 6px;
    font-size: 20px;
    font-weight: 700;
  }
  textarea {
    width: 100%;
    height: 200px;
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow:
      0px 0px 3px rgba(0, 0, 0, 0.1),
      0px 3px 6px rgba(0, 0, 0, 0.1);
    padding: 0.6em;
    transition: all 0.3s cubic-bezier(0.1, 0.8, 0.2, 1.6);
    margin: 0 0 0.5em 0;
    line-height: 1.4em;
  }

  .textareaEditable:hover textarea {
    background: none repeat scroll 0 0 var(--color-white);
    outline-width: 0;
    height: 400px;
  }

  // form, input[type]:not([type=search]):not([type=url]):not([type=hidden]):not([type=color]) {
  input[type]:not([type='search']):not([type='url']):not(
      [type='hidden']
    )::placeholder {
    font-size: 0.9em !important;
  }

  .btnSubmit {
    width: 100%;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: var(--color-white);
    font-weight: 700;
    background-color: #5cb85c;
    border-color: #4cae4c;

    &:hover {
      background-color: darken(#5cb85c, 10%);
      border-color: darken(#4cae4c, 12%);
    }
    &:active {
      background-color: darken(#5cb85c, 17%);
      border-color: darken(#4cae4c, 25%);
    }
    &:disabled {
      background-color: lighten(#5cb85c, 10%);
      border-color: lighten(#4cae4c, 12%);
    }
  }

  .btnReset {
    width: 100%;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: var(--color-white);
    font-weight: 700;
    background-color: #dc3545;
    border-color: #cc2b35;

    &:hover {
      background-color: darken(#dc3545, 10%);
      border-color: darken(#cc2b35, 12%);
    }
    &:active {
      background-color: darken(#dc3545, 17%);
      border-color: darken(#cc2b35, 25%);
    }
    &:disabled {
      background-color: lighten(#dc3545, 10%);
      border-color: lighten(#cc2b35, 12%);
    }
  }

  label {
    display: inline-block;
    font-size: 1.1em;
    color: #333;
    margin: 0 5px 5px 0;
  }

  .formControl {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px;
    font-size: 1.1em;
    color: #555;
    background-color: var(--color-white);
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition:
      border-color ease-in-out 0.15s,
      box-shadow ease-in-out 0.15s;
    .text-input-md {
      margin: 0;
    }
  }

  .small {
    max-width: 300px;
  }

  .alert {
    color: red;
    font-size: 0.9em;
    text-align: right;
    padding-top: 4px;
    padding-right: 4px;
  }

  .select-style {
    border: 1px solid #ccc;
    width: 100%;
    height: 34px;
    padding: 6px;
    font-size: 1.1em;
    color: #555;
    background-color: var(--color-white);
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border-radius: 4px;
    overflow: hidden;
    background: var(--color-white);
  }

  .select-style select:focus {
    outline: none;
  }
}

#autocompleteCustom1 {
  .evs_options {
    .evs_option {
      background-color: red;
      color: var(--color-white);
    }
    .evs_option:hover {
      background-color: darken(red, 20%);
    }
  }
}

// **********************************
// *   PAGE GAMIFICATION            *
// **********************************

$gamification-header: var(--color-dark-700);
$gamification-header-progress: var(--color-orange-500);

$account: #3bbeaa;
$account-progress: #a0e7dc;

$code: #7fafe2;
$code-progress: #badbff;

$prefecture-registration: #dc848f;
$prefecture-registration-progress: #efc2c8;

$code-exam: #5e5e81;
$code-exam-progress: #a6a6db;

$driving-exam: #e46255;
$driving-exam-progress: #f1ada6;

$driving-lesson: #f3b55a;
$driving-lesson-progress: #f3d7ae;

.warning-bar {
  width: calc(100% - 42px);
  height: 12px;
  margin: 4px 0;
  border: 2px solid var(--color-white);
  border-radius: 6px;
  background: linear-gradient(
    135deg,
    #b8b8b8 25%,
    #9d9d9d 25%,
    #9d9d9d 50%,
    #b8b8b8 50%,
    #b8b8b8 75%,
    #9d9d9d 75%
  );
  background-size: 40px 40px;
  background-position: 0 0;
  animation: 6s warning-bar-animated linear infinite;
}

.warning-pulse {
  animation: 1.2s animation-pulse ease-in-out infinite;
}

@keyframes animation-pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes warning-bar-animated {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 -125%;
  }
}

.gamification-faq {
  display: none;
  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 16px;
      font-weight: 400;
      color: var(--color-cypres);
      line-height: 18px;
      margin: 0 0 0 12px;
    }
  }
}

#gamification-global-header {
  background: $gamification-header;
  padding: 10px 16px;
  color: var(--color-white);
  // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin: 24px auto 0 auto;
  border-radius: 16px;

  @media screen and (max-width: 767px) {
    margin: 24px auto 0;
  }

  @media screen and (max-width: 632px) {
    margin: 24px 16px 0 16px;
  }

  .gamification-global-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .text {
      width: calc(100% - 35px);
      line-height: 20px;
    }

    .loader-bar {
      width: calc(100% - 35px);
      margin: 4px 0;
      .loader-bar-inner {
        background: $gamification-header-progress;
      }
    }

    .sub-text {
      width: calc(100% - 35px);
      font-weight: 400;
      line-height: 20px;
      margin: 0;
    }
  }
  .circle-arrow {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-white);
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    right: 19px;
    img {
      width: 16px;
    }
  }
}

#gamification-global-header.double-bandeau {
  margin-top: 8px;
  @media screen and (max-width: 767px) {
    margin: 0;
    border-radius: 0;
  }
}

.no-credit-for-exam {
  background: var(--color-red-500);
  padding: 16px 24px;
  color: var(--color-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 24px auto 0;
  border-radius: 16px;

  &.double-bandeau {
    margin: 8px auto 0;
  }
  .icon {
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    img {
      width: 100%;
    }
  }
  .text {
    font-size: 12px;
  }
  @media screen and (max-width: 767px) {
    margin: 0;
    padding: 16px;
    border-radius: 0;
    justify-content: flex-start;
    .icon {
      margin-right: 16px;
    }
  }
}

.cpf-starting-soon {
  background: var(--color-green-300);
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px auto 0;
  border-radius: 16px;

  @media screen and (max-width: 767px) {
    margin: 0;
    padding: 16px;
    border-radius: 0;
  }

  .cpf-wrapper {
    color: var(--color-white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 8px;
    .icon {
      min-width: 24px;
      min-height: 24px;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      img {
        width: 100%;
      }
    }
    .text {
      font-size: 12px;
    }
    @media screen and (max-width: 767px) {
      justify-content: flex-start;
      .icon {
        margin-right: 16px;
      }
    }
  }
}

.cpf-in-edof-error-status {
  background: var(--color-green-300);
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px auto 0;
  border-radius: 16px;

  @media screen and (max-width: 767px) {
    margin: 0;
    padding: 16px;
    border-radius: 0;
  }

  .cpf-wrapper {
    color: var(--color-white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 8px;
    .icon {
      min-width: 24px;
      min-height: 24px;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      img {
        width: 100%;
      }
    }
    .text {
      font-size: 12px;
    }
    @media screen and (max-width: 767px) {
      justify-content: flex-start;
      .icon {
        margin-right: 16px;
      }
    }
  }
}

#awaiting-credits-global-header {
  background: var(--color-red-500);
  padding: 16px 32px;
  color: var(--color-white);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 24px auto 0 auto;
  border-radius: 16px;
  font-size: 16px;
  font-weight: 400;

  .bold {
    font-weight: 500;
  }

  img {
    margin: 0 8px 0 0;
  }

  @media screen and (max-width: 767px) {
    padding: 16px;
    margin: 0;
    border-radius: 0;
    font-size: 12px;
    font-weight: 500;

    .bold {
      font-weight: 700;
    }
  }
}

.ion-content-base {
  --ion-background-color: var(--color-blue-50);
}

.ion-content-account {
  --ion-background-color: #3bbeaa;
}
.category-card-wrapper {
  .loader-bar-inner {
    height: 8px !important;
  }
}
#category-card-default {
  position: relative;
  .left-border {
    width: 5px;
    height: 52%;
    border-radius: 0 6px 6px 0;
    background: var(--color-neutral-300);
    position: absolute;
    left: 0;
  }
  .loader-bar-inner {
    background: var(--color-neutral-300);
  }
}
#category-card-account {
  position: relative;
  .left-border {
    width: 5px;
    height: 52%;
    border-radius: 0 6px 6px 0;
    background: $account;
    position: absolute;
    left: 0;
  }
  .loader-bar-inner {
    background: $account-progress;
  }
}
#my-badges-card-account {
  position: relative;
  .left-border {
    width: 5px;
    height: 52%;
    border-radius: 0 6px 6px 0;
    background: $account;
    position: absolute;
    left: 0;
  }
  .category-tag {
    background: $account;
  }
}
#category-detail-progress-card-account {
  background: $account;
  .loader-bar-inner {
    background: $account-progress;
  }
}
.strip-account {
  background: $account;
}

.ion-content-code {
  --ion-background-color: #7fafe2;
}
#category-card-code {
  position: relative;
  .left-border {
    width: 5px;
    height: 52%;
    border-radius: 0 6px 6px 0;
    background: $code;
    position: absolute;
    left: 0;
  }
  .loader-bar-inner {
    background: $code-progress;
  }
}
#my-badges-card-code {
  position: relative;
  .left-border {
    width: 5px;
    height: 52%;
    border-radius: 0 6px 6px 0;
    background: $code;
    position: absolute;
    left: 0;
  }
  .category-tag {
    background: $code;
  }
}
#category-detail-progress-card-code {
  background: $code;
  .loader-bar-inner {
    background: $code-progress;
  }
}
.strip-code {
  background: $code;
}

.ion-content-prefecture-registration {
  --ion-background-color: #dc848f;
}
#category-card-prefecture-registration {
  position: relative;
  .left-border {
    width: 5px;
    height: 52%;
    border-radius: 0 6px 6px 0;
    background: $prefecture-registration;
    position: absolute;
    left: 0;
  }
  .loader-bar-inner {
    background: $prefecture-registration-progress;
  }
}
#my-badges-card-prefecture-registration {
  position: relative;
  .left-border {
    width: 5px;
    height: 52%;
    border-radius: 0 6px 6px 0;
    background: $prefecture-registration;
    position: absolute;
    left: 0;
  }
  .category-tag {
    background: $prefecture-registration;
  }
}
#category-detail-progress-card-prefecture-registration {
  background: $prefecture-registration;
  .loader-bar-inner {
    background: $prefecture-registration-progress;
  }
}
.strip-prefecture-registration {
  background: $prefecture-registration;
}

.ion-content-code-exam {
  --ion-background-color: #5e5e81;
}
#category-card-code-exam {
  position: relative;
  .left-border {
    width: 5px;
    height: 52%;
    border-radius: 0 6px 6px 0;
    background: $code-exam;
    position: absolute;
    left: 0;
  }
  .loader-bar-inner {
    background: $code-exam-progress;
  }
}
#my-badges-card-code-exam {
  position: relative;
  .left-border {
    width: 5px;
    height: 52%;
    border-radius: 0 6px 6px 0;
    background: $code-exam;
    position: absolute;
    left: 0;
  }
  .category-tag {
    background: $code-exam;
  }
}
#category-detail-progress-card-code-exam {
  background: $code-exam;
  .loader-bar-inner {
    background: $code-exam-progress;
  }
}
.strip-code-exam {
  background: $code-exam;
}

.ion-content-driving-lesson {
  --ion-background-color: #f3b55a;
}
#category-card-driving-lesson {
  position: relative;
  .left-border {
    width: 5px;
    height: 52%;
    border-radius: 0 6px 6px 0;
    background: $driving-lesson;
    position: absolute;
    left: 0;
  }
  .loader-bar-inner {
    background: $driving-lesson-progress;
  }
}
#my-badges-card-driving-lesson {
  position: relative;
  .left-border {
    width: 5px;
    height: 52%;
    border-radius: 0 6px 6px 0;
    background: $driving-lesson;
    position: absolute;
    left: 0;
  }
  .category-tag {
    background: $driving-lesson;
  }
}
#category-detail-progress-card-driving-lesson {
  background: $driving-lesson;
  .loader-bar-inner {
    background: $driving-lesson-progress;
  }
}
.strip-driving-lesson {
  background: $driving-lesson;
}

.ion-content-driving-exam {
  --ion-background-color: #e46255;
}
#category-card-driving-exam {
  position: relative;
  .left-border {
    width: 5px;
    height: 52%;
    border-radius: 0 6px 6px 0;
    background: $driving-exam;
    position: absolute;
    left: 0;
  }
  .loader-bar-inner {
    background: $driving-exam-progress;
  }
}
#my-badges-card-driving-exam {
  position: relative;
  .left-border {
    width: 5px;
    height: 52%;
    border-radius: 0 6px 6px 0;
    background: $driving-exam;
    position: absolute;
    left: 0;
  }
  .category-tag {
    background: $driving-exam;
  }
}
#category-detail-progress-card-driving-exam {
  background: $driving-exam;
  .loader-bar-inner {
    background: $driving-exam-progress;
  }
}
.strip-driving-exam {
  background: $driving-exam;
}

#customModalLastObtainedBadge {
  .evs_modal_container {
    @media screen and (max-width: 768px) {
      max-width: 100% !important;
      max-height: 100% !important;
      width: 100% !important;
      height: 100% !important;
      border-radius: 0 !important;
      display: flex;
      justify-content: center;
      margin: auto 0 !important;
    }
    .evs_modal_content {
      height: 100%;
      padding: 40px 95px;
      margin: 0;
      max-height: none;
      position: relative;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      @media screen and (max-width: 768px) {
        padding: 68px 16px;
      }
      .close-btn {
        position: absolute;
        top: 16px;
        right: 16px;
      }
      .congrats {
        font-size: 24px;
        font-weight: 700;
        line-height: 27px;
        color: var(--color-hiorange);
        margin: 0 0 16px 0;
      }
      .desc {
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
        color: var(--color-cypres);
        margin: 0 0 30px 0;
      }
      .badge-container {
        margin: 0 0 16px 0;
        padding: 0 10px;
        background: url('/assets/svg/gamification/Background_Stars.svg');
        background-repeat: no-repeat;
        background-size: contain;
        .badge {
          height: 180px;
          width: 180px;
        }
      }
      .badge-title {
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
        color: var(--color-cypres);
        margin: 0 0 8px 0;
        &.color-account {
          color: $account;
        }
        &.color-code {
          color: $code;
        }
        &.color-prefecture-registration {
          color: $prefecture-registration;
        }
        &.color-code-exam {
          color: $code-exam;
        }
        &.color-driving-exam {
          color: $driving-exam;
        }
        &.color-driving-lesson {
          color: $driving-lesson;
        }
      }
      .badge-subtitle {
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        color: var(--color-cypres);
        opacity: 0.8;
        margin: 0 0 32px 0;
      }
    }
  }
}

#customModalStartUpNEPH,
#customModalNEPHInfos,
#customModalANTSInfos {
  .evs_modal_container {
    max-width: 600px;
    @media screen and (max-width: 768px) {
      max-width: 100% !important;
      max-height: 100% !important;
      width: 100% !important;
      height: 100% !important;
      border-radius: 0 !important;
      display: flex;
      justify-content: center;
      margin: auto 0 !important;
    }
    .evs_modal_content {
      height: 100%;
      padding: 24px 94px 40px 94px;
      margin: 0;
      max-height: none;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 40px;
      @media screen and (max-width: 768px) {
        padding: 64px 24px 24px 24px;
        justify-content: space-between;
      }
      .close-btn {
        position: absolute;
        top: 16px;
        right: 16px;
      }
      .back-btn {
        position: absolute;
        top: 16px;
        left: 16px;
      }
      .content-wrapper {
        color: var(--color-font);
        display: flex;
        flex-direction: column;
        gap: 40px;

        h4,
        h5 {
          text-align: center;
          margin: 0;
        }
        .text-wrapper {
          .article-link {
            color: var(--color-orange-500);
            text-decoration: underline;
          }
        }
        .embed-video {
          width: 100%;
          height: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
          iframe {
            height: 100%;
            width: 100%;
          }
        }
      }
      .button-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
  }
}

#customModalNEPHInfos {
  .evs_modal_container {
    .evs_modal_content {
      .button-wrapper {
        button {
          padding: 18px 12px;
        }
      }
    }
  }
}

#customModalANTSInfos {
  .evs_modal_container {
    .evs_modal_content {
      gap: 24px;
    }
  }
}

#customModalTeacherNotFound {
  .evs_modal_container {
    max-width: 500px;
    @media screen and (max-width: 768px) {
      max-width: 100% !important;
      max-height: 100% !important;
      width: 100% !important;
      height: 100% !important;
      border-radius: 0 !important;
      display: flex;
      justify-content: center;
      margin: auto 0 !important;
    }
    .evs_modal_content {
      height: 100%;
      padding: 24px;
      margin: 0;
      max-height: none;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 40px;
      @media screen and (max-width: 768px) {
        padding: 64px 24px 24px 24px;
        justify-content: space-between;
      }
      .close-btn {
        position: absolute;
        top: 16px;
        right: 16px;
      }
      .content-wrapper {
        color: var(--color-font);
        display: flex;
        flex-direction: column;
        gap: 16px;

        h4 {
          text-align: center;
          margin: 0;
          @media screen and (max-width: 768px) {
            text-align: left;
          }
        }

        .text-wrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;
          p {
            margin: 0;
          }
          .teacher-card {
            width: 100%;
            padding: 16px;
            display: flex;
            gap: 16px;
            border-radius: 12px;
            background-color: #fff0ea;

            img {
              max-width: 48px;
              max-height: 48px;
              border-radius: 8px;
            }

            .teacher-card-text {
              display: flex;
              flex-direction: column;
              justify-content: center;
              p {
                margin: 0;
                font-weight: 500;
                font-size: 12px;
              }
              .teacher-card-text-title {
                font-weight: 700;
                font-size: 16px;
                color: var(--color-orange-700);
              }
            }

            a {
              margin-left: auto;
              svg-icon-comp {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 48px;
                border-radius: 12px;
                background-color: var(--color-orange-600);
              }
            }
          }
          .teacher-infos {
            display: flex;
            justify-content: space-between;

            .location-copy {
              position: relative;
              display: flex;
              align-items: center;
              gap: 8px;

              .location-copied {
                font-size: 12px;
                font-weight: 500;
                color: var(--color-white);
                background-color: var(--color-black);
                border-radius: 16px;
                padding: 8px 12px;
                position: absolute;
                right: 32px;
                white-space: nowrap;
              }
            }

            p {
              margin: 0;

              &:first-child {
                font-weight: 400;
              }

              &:last-child {
                font-weight: 500;
              }
            }
          }
          .map {
            width: 100%;
            height: 130px;
            border-radius: 16px;
          }
        }
      }
      .button-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .signal-button-warning {
          display: flex;
          gap: 16px;
          align-items: center;
          padding: 16px;
          border-radius: 16px;
          background-color: var(--color-red-50);
          color: var(--color-red-500);

          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}

#customModalReportTeacherNotFound {
  .evs_modal_container {
    max-width: 500px;
    @media screen and (max-width: 768px) {
      max-width: 100% !important;
      max-height: 100% !important;
      width: 100% !important;
      height: 100% !important;
      border-radius: 0 !important;
      display: flex;
      justify-content: center;
      margin: auto 0 !important;
    }
    .evs_modal_content {
      height: 100%;
      padding: 24px;
      margin: 0;
      max-height: none;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 40px;
      @media screen and (max-width: 768px) {
        padding: 64px 24px 24px 24px;
        justify-content: space-between;
      }
      .close-btn {
        position: absolute;
        top: 16px;
        right: 16px;
      }
      .content-wrapper {
        color: var(--color-font);
        display: flex;
        flex-direction: column;
        gap: 16px;

        h4 {
          text-align: center;
          margin: 0;
          @media screen and (max-width: 768px) {
            text-align: left;
          }
        }

        .text-wrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;

          p {
            margin: 0;
          }

          .evs-form {
            .report-teacher-not-found-files {
              &:focus-within label {
                font-size: 16px !important;
                margin: 0 !important;
              }

              &:hover {
                .fake-field {
                  background-color: #dfe2e9;
                }
              }

              .fake-field {
                border: 1px dashed #dfe2e9;
                background-color: #f2f5f9;
                margin: 0;
                top: 0;
              }

              input {
                width: 100%;
              }

              .fake-label-wrapper {
                width: 100%;
                height: 55px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                position: absolute;
                top: 0;
                z-index: 3;
                pointer-events: none;
                cursor: pointer;

                img {
                  width: 24px;
                  height: 24px;
                }

                label {
                  width: auto;
                  position: initial;
                  color: #5f6677;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 24px;
                }
              }
            }
          }

          .files-wrapper {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .file-card {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border: 2px solid var(--color-neutral-400);
              padding: 8px;
              border-radius: 16px;

              img {
                cursor: pointer;
                pointer-events: all;
              }
            }
          }
        }
      }
      .button-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .signal-button-warning {
          display: flex;
          gap: 16px;
          align-items: center;
          padding: 16px;
          border-radius: 16px;
          background-color: #ffebe1;
          color: var(--color-orange-700);

          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}

#customModalReportTeacherNotFoundSuccessful {
  .evs_modal_container {
    max-width: 500px;
    @media screen and (max-width: 768px) {
      max-width: 100% !important;
      max-height: 100% !important;
      width: 100% !important;
      height: 100% !important;
      border-radius: 0 !important;
      display: flex;
      justify-content: center;
      margin: auto 0 !important;
    }
    .evs_modal_content {
      height: 100%;
      padding: 64px 24px 24px 24px;
      margin: 0;
      max-height: none;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 40px;
      @media screen and (max-width: 768px) {
        justify-content: space-between;
      }
      .close-btn {
        position: absolute;
        top: 16px;
        right: 16px;
      }
      .content-wrapper {
        color: var(--color-font);
        display: flex;
        flex-direction: column;
        gap: 32px;

        img {
          max-width: 88px;
          max-height: 88px;
          margin: 0 auto;
        }

        h4 {
          text-align: center;
          margin: 0;
          @media screen and (max-width: 768px) {
            text-align: left;
          }
        }

        .text-wrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;

          p {
            text-align: center;
            margin: 0;
          }
        }
      }
      .button-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    }
  }
}
/* -------------------------------------------------------- */
// WOOTRIC
/* -------------------------------------------------------- */

#wootric-modal {
  .wootric-beacon-parent {
    @media screen and (max-width: 768px) {
      padding: 0.25rem 0 !important;
    }
    li {
      @media screen and (min-width: 768px) {
        border-radius: 50% !important;
      }
    }
  }
  #wootric-question p {
    @media screen and (max-width: 768px) {
      font-size: 0.9rem !important;
      margin-top: 1.3rem !important;
    }
  }
  .wootric-powered-by .wootric-link,
  .wootric-feedback-disclaimer .wootric-disclaimer-link {
    @media screen and (max-width: 768px) {
      pointer-events: none !important;
    }
  }
  @media only screen and (max-width: 768px) {
    .wootric-mobile-modal-zoomed #wootric-question {
      margin: 1rem 0 !important;
    }
    .wootric-mobile-modal-zoomed #wootric-fullscore ul li {
      height: 10vw !important;
      line-height: 9vw !important;
    }
    #wootric-fullscore ul li,
    #wootric-score ul li {
      font-size: 1.1rem !important;
    }
    label,
    #wootric-likely-label,
    #wootric-not-likely-label {
      font-size: 0.85rem !important;
    }
  }
}

// logo wootric
#wootric-modal .wootric-footer {
  display: none !important;
}

// ------------------------
//    POST REGISTRATION
// ------------------------

#customModalPostRegistration,
#customModalPostRegistrationCordova {
  .evs_modal_container {
    @media screen and (max-width: 768px) {
      max-width: 100% !important;
      max-height: 100% !important;
      width: 100% !important;
      height: 100% !important;
      border-radius: 0 !important;
      display: flex;
      justify-content: center;
      margin: auto 0 !important;
    }
    .evs_modal_content {
      max-height: fit-content;
      text-align: center;
      .illu-first-screen,
      .illu-third-screen,
      .illu-fourth-screen {
        max-width: 140px;
      }
      .illu-second-screen {
        max-width: 72px;
      }
      min-width: 320px;
      @media screen and (max-width: 768px) {
        min-width: initial;
      }
      h3 {
        img {
          width: 24px;
          margin: 0 8px;
        }
        img:first-child {
          margin-left: 0;
        }
      }
      .bullet-feu,
      .bullet-car,
      .bullet-book,
      .bullet-graduation {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        @media screen and (max-width: 768px) {
          justify-content: flex-start;
          align-items: flex-start;
          text-align: left;
        }
        margin-bottom: 8px;
        b {
          display: contents;
        }
        img {
          width: 24px;
          margin-right: 8px;
        }
      }
      .button-smiley {
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 52px;
        img {
          width: 24px;
          margin-left: 8px;
        }
      }
    }
    .form-post-registration {
      input[type='radio'] {
        display: none;
      }
      label {
        cursor: pointer;
      }
      input[type='radio']:checked + label {
        border: 2px solid #ff7c00;
        color: #ff7c00;
        font-weight: 700;
        background-color: var(--color-neutral-100);
      }
      div {
        position: relative;
        margin-bottom: 16px;
        label img {
          position: absolute;
          left: 16px;
        }
      }
    }
    button {
      white-space: normal !important;
    }
  }
}

reserver-lecon-page {
  ion-content {
    --ion-background-color: var(--color-blue-50);
  }
}

// ------------------------
//    STUDENT DOCUMENTS
// ------------------------

.student-documents {
  #customModalNEPH,
  #customModalCategPermis,
  #customModalLicenseNumber,
  #customModalCategPermisChanged {
    .evs_modal_content {
      justify-content: flex-start;
      height: calc(100% - 2em);

      p {
        font-size: 16px;
        line-height: 18px;

        // .indicator-modale {
        //   color: #85BDCB;
        //   cursor: pointer;

        //   &:hover {
        //     text-decoration: underline;
        //   }
        // }
      }

      img {
        max-width: 80%;
        margin-bottom: 30px;
      }

      @media screen and (max-width: 767px) {
        img {
          max-width: 100%;
          margin-bottom: 30px;
        }
      }
    }
  }

  #customModalJustifSavoirPlus {
    .evs_modal_content {
      p {
        @media screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
  }

  #customModalNEPH,
  #customModalCategPermis,
  #customModalLicenseNumber {
    .evs_modal_content {
      p {
        width: 100%;
        text-align: justify !important;
      }
    }
  }

  #customModalCategPermisChanged {
    .evs_modal_content {
      max-height: 500px;

      button {
        margin-top: 20px;
      }
    }
  }

  #customModalDeleteDocument {
    .button-wrapper {
      display: flex;
      width: 100%;
      max-width: 250px;
      margin: 0 auto;
      justify-content: space-between;

      & > button,
      & > loading-button-comp {
        width: calc(50% - 5px);
      }
    }
  }
}

// ------------------------
//    CODE EXAM RESERVATION
// ------------------------
.code-exam-reservation {
  #customModalNEPH,
  #customModalCategPermisChanged,
  #customModalNEPHInfos {
    .evs_modal_content {
      justify-content: flex-start;
      height: calc(100% - 2em);

      p {
        font-size: 16px;
        line-height: 18px;

        // .indicator-modale {
        //   color: #85BDCB;
        //   cursor: pointer;

        //   &:hover {
        //     text-decoration: underline;
        //   }
        // }
      }

      img {
        max-width: 80%;
        margin-bottom: 30px;
      }

      @media screen and (max-width: 767px) {
        img {
          max-width: 100%;
          margin-bottom: 30px;
        }
      }
    }
  }

  #customModalNEPH {
    .evs_modal_content {
      p {
        width: 100%;
        text-align: justify !important;
      }
    }
  }

  #customModalNEPHInfos {
    .evs_modal_container {
      max-width: 644px;
      @media screen and (max-width: 767px) {
        max-width: 100% !important;
        max-height: 100% !important;
        border-radius: 0 !important;
        width: 100%;
        height: 100%;
      }
      .evs_modal_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-height: 500px;
        text-align: left;
        padding: 32px;
        margin: 0;

        @media screen and (max-width: 767px) {
          max-width: 100%;
          max-height: 100%;
          width: 100%;
          height: 100%;
        }

        h4 {
          @media screen and (max-width: 767px) {
            margin: 0 0 24px;
            text-align: center;
          }
        }

        img {
          cursor: pointer;
          align-self: flex-end;
          width: 32px;
          height: 32px;
          background-color: #eff1f3;
          border-radius: 50%;
          padding: 5px;
          margin: 0 0 24px;
        }

        button {
          margin-top: 20px;

          @media screen and (max-width: 767px) {
            margin-top: auto;
          }
        }
      }
    }
  }

  #customModalCategPermisChanged {
    .evs_modal_content {
      max-height: 500px;

      button {
        margin-top: 20px;
      }
    }
  }

  #customModalDeleteDocument {
    .button-wrapper {
      display: flex;
      width: 100%;
      max-width: 250px;
      margin: 0 auto;
      justify-content: space-between;

      & > button,
      & > loading-button-comp {
        width: calc(50% - 5px);
      }
    }
  }
}
#customModalStudentInfo {
  .evs_modal_close_btn {
    background: url(assets/2022/icons/close-on.svg) !important;
  }
  .evs_modal_container {
    max-width: none !important;
    max-height: none !important;
    width: 100% !important;
    height: 100% !important;
    display: flex;
    justify-content: flex-end;
    margin: auto 0 !important;
    border-radius: 0 !important;
  }
  .evs_modal_content {
    justify-content: flex-end !important;
    height: 75% !important;
  }
  .evs-form-cpt {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  .evs-form div input[type='text'],
  input[type='tel'] {
    border: 2px solid var(--color-neutral-200);
  }
}
#reasonAutoComp {
  .evs_options {
    background-color: var(--color-white);
  }
}
.rgpd-text {
  font-size: 12px;
  color: #494e5a;
  text-align: center;
}
.rgpd-link {
  font-size: 12px;
  color: #494e5a !important;
  font-weight: normal;
}
#customModalPubBva,
#customModalPubPackPermis,
#customModalPubCpf {
  .evs_modal_container {
    height: 100%;
    width: 100%;
    max-width: none !important;
    max-height: 100%;
    border-radius: 0 !important;
    background-color: var(--color-blue-50);
  }
  .evs_modal_content {
    overflow: hidden;
    height: 100%;
  }
  .pub-content {
    position: absolute;
    height: 100%;
    right: 0px;
    left: 0px;
    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      b {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
      }
    }
    .button-box {
      position: absolute;
      bottom: calc(0px + 1em);
      margin: 33px 24px;
      right: 0;
      left: 0;
    }
  }
  .asset {
    img {
      max-height: 215px;
    }
  }
  .logo {
    img {
      max-width: 144px;
    }
  }
}

#customModalLeconAvailabilities {
  .evs_modal_container {
    margin-top: 72px !important;
    max-height: calc(100% - 8em - 72px);
    width: 100% !important;
    max-width: 668px;
    border-radius: 40px 0 0 40px;
    background-color: var(--color-neutral-50) !important;
    overflow: hidden !important;
    @media screen and (max-width: 1023px) {
      max-width: 100% !important;
      max-height: 100%;
      border-radius: 0 !important;
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
    }
    .evs_modal_content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0 !important;
      padding-right: 0 !important;
      overflow: hidden !important;
      @media screen and (max-width: 1023px) {
        max-height: 100% !important;
      }
    }
    .header-content-wrapper {
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }
    .sticky-acheter-credits {
      z-index: 1;
      max-width: 668px;
      width: 100%;
      background-color: var(--color-neutral-50);
      padding: 16px 24px;
      border-radius: 0 0 16px 16px;
      @media screen and (max-width: 1023px) {
        max-width: 100%;
      }
    }
    .header-desktop {
      position: relative;
      display: flex;
      justify-content: center;
      text-align: center;
      padding: 16px 24px;
      border-bottom: 1px solid var(--color-neutral-200);
      .close-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
        width: 24px;
        height: 24px;
        background-image: url('assets/2022/icons/close-off.svg');
        background-size: 24px;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
      }
      .teacher-details {
        .teacher-picture {
          width: 40px;
          height: 40px;
          border-radius: 12px;
          margin: auto;
          background-color: rgba(0, 0, 0, 0.1);
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          margin-bottom: 8px;
        }
        .details {
          color: #2a2d34;
          font-size: 16px;
          font-weight: 400;
          .teacher-name {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 4px;
          }
        }
      }
    }
    .header-mobile {
      position: relative;
      display: flex;
      justify-content: flex-start;
      text-align: left;
      padding: 16px 24px;
      border-bottom: 1px solid var(--color-neutral-200);
      .back-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;
        width: 24px;
        height: 24px;
        background-image: url('assets/2022/icons/arrow-left-2-off.svg');
        background-size: 24px;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
      }
      .teacher-details {
        display: flex;
        margin-left: 24px;
        .teacher-picture {
          width: 40px;
          height: 40px;
          border-radius: 12px;
          margin: auto;
          background-color: rgba(0, 0, 0, 0.1);
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          margin-right: 8px;
        }
        .details {
          color: #2a2d34;
          font-size: 16px;
          font-weight: 400;
          .teacher-name {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 4px;
          }
        }
      }
    }
    .content {
      overflow-y: auto;
      max-width: 100%;
      padding: 16px 24px;
      display: flex;
      justify-content: center;

      .max-width-content {
        width: 100%;
        max-width: 343px;

        @media screen and (max-width: 1023px) {
          max-width: 100%;
        }
      }

      .icon-alert-yelow {
        min-width: 22px;
        width: 22px;
        height: 22px;
        background-image: url('/assets/svg/ico-alert.svg');
        background-size: 22px;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 8px;
      }
      .icon-alert-red {
        min-width: 22px;
        width: 22px;
        height: 22px;
        background-image: url('/assets/svg/ico-close-square.svg');
        background-size: 22px;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 8px;
      }
      .info-alert-yellow {
        display: flex;
        align-items: flex-start;
        padding: 8px 16px;
        background-color: #fff7e5;
        border: 1px solid #604409;
        border-radius: 16px;
        margin-bottom: 24px;
        .text-alert {
          font-size: 14px;
          line-height: 16px;
          color: #604409;
          font-weight: 400;
          .title {
            font-weight: 700;
            margin-bottom: 4px;
          }
        }
      }
      .info-alert-red {
        display: flex;
        align-items: flex-start;
        padding: 8px 16px;
        background-color: #fdeced;
        border: 1px solid #a41f13;
        border-radius: 16px;
        margin-bottom: 24px;
        .text-alert {
          font-size: 14px;
          line-height: 16px;
          color: #a41f13;
          font-weight: 400;
          .title {
            font-weight: 700;
            margin-bottom: 4px;
          }
        }
      }
      .title {
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        text-align: left;
        color: var(--color-main-text);
        margin-bottom: 16px;
      }
      .lecon-duration {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        .toggle {
          width: 206px;
          height: 46px;
          margin: auto;
          .bg-toggle {
            display: flex;
            align-items: center;
            flex-direction: row;
            column-gap: 4px;
            border-radius: 16px;
            background-color: var(--color-white);
            border: 1px solid var(--color-neutral-100);
            padding: 4px;
            color: var(--color-main-text);
            span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 32px;
              border-radius: 12px;
              padding: 8px 16px;
              font-size: 14px;
              font-weight: 500;
              white-space: nowrap;
              color: var(--color-main-text);
              &:hover {
                background-color: var(--color-neutral-100);
              }
            }
            span.active {
              background-color: var(--color-orange-500);
              color: var(--color-white);
            }
          }
        }
      }
      #customExpandreserverLecon {
        margin-bottom: 8px;
        background-color: var(--color-white);
        padding: 16px 12px;
        border-radius: 16px;
        .collapsible-header {
          padding: 0;
        }
        .collapsible-active {
          position: relative;
          &:after {
            content: '';
            position: absolute;
            bottom: -16px;
            left: 10%;
            width: 80%;
            height: 1px;
            background-color: #eff1f3;
          }
        }
        .collapsible-title {
          font-size: 14px;
          font-weight: 500;
          color: var(--color-main-text);
          &:after {
            width: 16px;
            height: 16px;
          }
        }
        .collapsible-content-inner {
          padding-top: 32px;
          .alert-no-dispo-for-duration {
            padding: 8px 16px;
            background-color: #fff7e5;
            border-radius: 12px;
            width: 100%;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            color: #604409;
          }
          .badge-container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 16px;
            height: auto;
            .badge {
              width: 100%;
              padding: 8px 16px;
              border-radius: 16px;
              text-align: center;
              background-color: #e3f3ff;
              color: #0248ac;
              cursor: pointer;
            }
            .badged-disabled {
              background-color: #e5e7ea;
              color: #b1b3b5;
              cursor: initial;
            }
          }
        }
      }
    }
    .selectPdrFromTeacher {
      padding: 16px;
      text-align: center;
      @media screen and (max-width: 1023px) {
        text-align: left;
      }
      .title {
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        color: var(--color-main-text);
        margin: 8px 0 24px 0;
        @media screen and (max-width: 1023px) {
          text-align: left;
          margin: 8px 0 24px 8px;
        }
      }
      .locations-container {
        width: 320px;
        max-width: 320px;
        margin: auto;
        @media screen and (max-width: 1023px) {
          width: 100%;
          max-width: 100%;
          margin: initial;
          background-color: var(--color-white);
          border-radius: 16px;
        }
        .location {
          display: flex;
          align-items: center;
          background-color: var(--color-white);
          padding: 8px 16px;
          border-radius: 16px;
          border: 1px solid var(--color-neutral-100);
          margin-bottom: 8px;
          cursor: pointer;
          &:last-child {
            margin-bottom: 0;
          }
          @media screen and (max-width: 1023px) {
            background-color: initial;
            border: none;
            border-radius: 0;
            border-bottom: 1px solid #eff1f3;
            &:last-child {
              border-bottom: none;
            }
          }
          .icon {
            width: 32px;
            height: 32px;
            margin-right: 8px;
            background-image: url('assets/svg/ico-pin-conduite.svg');
            background-size: 32px;
            background-repeat: no-repeat;
            background-position: center;
            @media screen and (max-width: 1023px) {
              display: none;
            }
          }
          .location-details {
            text-align: left;
            .name {
              font-size: 14px;
              font-weight: 500;
            }
            .city {
              font-size: 12px;
              font-weight: 500;
              color: var(--color-neutral-500);
            }
          }
          &:hover {
            background-color: var(--color-neutral-100);
            @media screen and (max-width: 767px) {
              background-color: initial;
            }
          }
        }
      }
    }
    .confirmation-container {
      position: relative;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .content {
        @media screen and (max-width: 1023px) {
          margin-bottom: 58px;
        }
        .title {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 700;
          color: var(--color-main-text);
          margin-bottom: 16px;
        }
        .card {
          position: relative;
          border-radius: 16px;
          padding: 16px;
          background-color: var(--color-white);
          color: var(--color-main-text);
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
          .teacher {
            display: flex;
            align-items: center;
          }
          .photo {
            width: 32px;
            height: 32px;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 50%;
            margin-right: 8px;
          }
          .icon-calendar {
            width: 16px;
            height: 16px;
            background-image: url('/assets/svg/ico-calendar.svg');
            background-size: 12px;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
          }
          .icon-pin {
            width: 16px;
            height: 16px;
            background-image: url('/assets/svg/black-pin.svg');
            background-size: 12px;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
          }
          .icon-check {
            width: 16px;
            height: 16px;
            background-image: url('/assets/svg/blue-check.svg');
            background-size: 12px;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
          }
          .icon-black-star {
            width: 16px;
            height: 16px;
            background-image: url('/assets/svg/black-star.svg');
            background-size: 12px;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
          }
          .icon-volant {
            width: 16px;
            height: 16px;
            background-image: url('/assets/svg/ico-volant.svg');
            background-size: 12px;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
          }
          .icon-add-user {
            width: 16px;
            height: 16px;
            background-image: url('/assets/svg/ico-add-user.svg');
            background-size: 12px;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 4px;
          }
          .needed-credits,
          .available-credits {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;
          }
          .needed-credits {
            font-weight: 700;
          }
          .available-credits {
            margin-bottom: 4px;
          }
          .expiration-date {
            font-size: 12px;
            font-weight: 400;
            color: var(--color-neutral-500);
          }
        }
      }
      .sticky-controls {
        max-width: 343px;
        margin: auto;
        padding: 0 24px;
        margin-bottom: 16px;
        @media screen and (max-width: 1023px) {
          max-width: 100%;
          position: fixed;
          bottom: 0;
          width: 100%;
          background-color: var(--color-neutral-50);
          padding: 16px 24px;
          margin-bottom: 0;
        }
        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          ion-spinner {
            color: var(--color-white);
          }
        }
        .fix-btn-size {
          padding: 7px 24px !important;
        }
      }
    }
    .validated-lesson {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      padding: 16px;
      font-size: 16px;
      font-weight: 700;
      line-height: 18px;
      .header {
        padding: 8px 0;
        margin-bottom: 16px;
      }
      .teacher-picture {
        width: 96px;
        height: 96px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 24px;
        margin: auto;
        margin-bottom: 16px;
      }
      .text {
        margin-bottom: 24px;
      }
      .controls {
        .btn {
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.validated-lesson-fix {
  .evs_modal_container {
    max-width: 375px !important;
    border-radius: 16px !important;
    max-height: initial !important;
    height: initial !important;
    justify-content: center;
  }
}

#customModalFavTeacher {
  .evs_modal_container {
    width: 600px;
    border-radius: 16px !important;
    overflow: hidden !important;
    @media screen and (max-width: 767px) {
      width: 100% !important;
      bottom: 0 !important;
      border-radius: 12px 12px 0 0 !important;
    }
  }
  .content {
    padding: 40px 56px;
    position: relative;
    @media screen and (max-width: 767px) {
      padding: 16px;
      // HOT FIX
      padding-bottom: var(--safe-area-inset-bottom-px);
    }
    .header {
      margin-bottom: 16px;
      .teacher {
        display: flex;
        align-items: center;
        padding: 0 16px;
        .photo {
          width: 64px;
          height: 64px;
          border-radius: 16px;
          background-color: rgba(0, 0, 0, 0.1);
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          margin-right: 8px;
        }
        .teacher-details {
          .teacher-name {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 4px;
          }
          .teacher-is-auto {
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
      .close-btn {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        img {
          width: 24px;
        }
      }
    }
    .controls {
      background-color: var(--color-white);
      border-radius: 16px;
      overflow: hidden;
      padding: 16px;
      hr {
        height: 1px;
        background-color: #dfe2e9;
        margin-bottom: 16px;
      }
      .containerDiv {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        font-size: 14px;
        font-weight: 500;
        padding: 0px 0 12px 0;
        margin-bottom: 12px;
        cursor: pointer;
        &:hover {
          font-weight: 700;
        }
        &:last-child {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }
        span {
          flex: 5;
        }
        .containerImg {
          flex: 1;
          max-width: 24px;
          img {
            width: 24px;
          }
        }
      }
    }
  }
}

#customModalReserverLeconPageConduite {
  @media screen and (max-width: 767px) {
    height: calc(100% - 72px) !important;
    top: 72px !important;
  }
}

#customModalReserverLeconPageConduiteDevice {
  @media screen and (max-width: 767px) {
    height: calc(100% - 56px) !important;
    top: 0 !important;
  }
}

#customModalReserverLeconPageConduite,
#customModalReserverLeconPageConduiteDevice {
  z-index: 7000 !important;
  .evs_modal_container {
    width: 668px !important;
    background-color: var(--color-neutral-50) !important;
    @media screen and (max-width: 767px) {
      max-width: 100% !important;
      max-height: 100% !important;
      border-radius: 0 !important;
      width: 100% !important;
    }
    .evs_modal_content {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  .header-desktop {
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 16px 24px;
    border-bottom: 1px solid var(--color-neutral-200);
    .close-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
      width: 24px;
      height: 24px;
      background-image: url('assets/2022/icons/close-off.svg');
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }
    .teacher-details {
      .teacher-picture {
        width: 40px;
        height: 40px;
        border-radius: 12px;
        margin: auto;
        background-color: rgba(0, 0, 0, 0.1);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 8px;
      }
      .details {
        color: #2a2d34;
        font-size: 16px;
        font-weight: 400;
        .teacher-name {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 4px;
        }
      }
    }
  }
  .header-mobile {
    position: relative;
    display: flex;
    justify-content: flex-start;
    text-align: left;
    padding: 16px 24px;
    border-bottom: 1px solid var(--color-neutral-200);
    .back-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
      width: 24px;
      height: 24px;
      background-image: url('assets/2022/icons/arrow-left-2-off.svg');
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }
    .teacher-details {
      display: flex;
      margin-left: 24px;
      .teacher-picture {
        width: 40px;
        height: 40px;
        border-radius: 12px;
        margin: auto;
        background-color: rgba(0, 0, 0, 0.1);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 8px;
      }
      .details {
        color: #2a2d34;
        font-size: 16px;
        font-weight: 400;
        .teacher-name {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 4px;
        }
      }
    }
  }
  .selectPdrFromTeacher {
    padding: 16px;
    text-align: center;
    @media screen and (max-width: 767px) {
      text-align: left;
    }
    .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      text-align: center;
      color: var(--color-main-text);
      margin: 8px 0 24px 0;
      @media screen and (max-width: 767px) {
        text-align: left;
        margin: 8px 0 24px 8px;
      }
    }
    .locations-container {
      width: 320px;
      max-width: 320px;
      margin: auto;
      @media screen and (max-width: 767px) {
        width: 100%;
        max-width: 100%;
        margin: initial;
        background-color: var(--color-white);
        border-radius: 16px;
      }
      .location {
        display: flex;
        align-items: center;
        background-color: var(--color-white);
        padding: 8px 16px;
        border-radius: 16px;
        border: 1px solid var(--color-neutral-100);
        margin-bottom: 8px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
        @media screen and (max-width: 767px) {
          background-color: initial;
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #eff1f3;
          &:last-child {
            border-bottom: none;
          }
        }
        .icon {
          width: 32px;
          height: 32px;
          margin-right: 8px;
          background-image: url('assets/svg/ico-pin-conduite.svg');
          background-size: 32px;
          background-repeat: no-repeat;
          background-position: center;
          @media screen and (max-width: 767px) {
            display: none;
          }
        }
        .location-details {
          text-align: left;
          .name {
            font-size: 14px;
            font-weight: 500;
          }
          .city {
            font-size: 12px;
            font-weight: 500;
            color: var(--color-neutral-500);
          }
        }
        &:hover {
          background-color: var(--color-neutral-100);
          @media screen and (max-width: 767px) {
            background-color: initial;
          }
        }
      }
    }
  }
}

.custom-spinner {
  vertical-align: middle;
  left: 10px;
}
